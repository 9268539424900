import { t } from 'i18next';
import React from 'react';

import ChannelType from './ChannelType';
import { getIconTranslationString } from './CommentIconContent.utils';

function withoutData(title: string) {
  return (
    <div>
      <b>{title}</b>
      <hr />
    </div>
  );
}

function EmptyContent(props: { channel: ChannelType }) {
  const message = t(getIconTranslationString(props.channel));

  return withoutData(message);
}

export default EmptyContent;

import type { PhoneValidResult } from 'phone';

interface Sanitization {
  value: string;
  data: {
    allowedTags: string[];
    selfClosing: string[];
    allowedSchemes: string[];
    allowedAttributes: Record<string, unknown>;
    disallowedTagsMode: 'escape' | 'discard' | 'recursiveEscape' | undefined;
    allowProtocolRelative?: boolean | undefined;
    allowedSchemesAppliedToAttributes?: any[];
  };
}

interface soundChannels {
  data: {
    channels: number[];
  };
}

export interface ISalesforceSettingsData {
  hiddenFields: string[];
  taskStatuses: string[];
  leadStatuses: string[];
  opportunityStages: string[];
  taskDefaultOwnerId: string;
  leadDefaultOwnerId: string;
  opportunityDefaultOwnerId: string;
  contactFieldsToFetch: string[];
  accountFieldsToFetch: string[];
  userFieldsToFetch: string[];
  contactFieldView: string;
  contactFieldId?: string;
  accountFieldView: string;
  accountFieldId?: string;
  userFieldView: string;
  fieldsMapping: {
    case: { [key: string]: string };
    task: { [key: string]: string };
    lead: { [key: string]: string };
    opportunity: { [key: string]: string };
  };
  closeDateInSeconds: boolean;
  dueDateInSeconds: boolean;
  leadDefaultStatus: '';
  taskDefaultStatus: '';
  opportunityDefaultStage: '';
  objects?: Record<string, string>;
  collections?: Record<string, string>;
  fetchPicklistValues?: Record<string, string>;
  standardObjects?: boolean;
}

// TODO: write (or find library) exact types e.g. FI BE etc.
export type Localization = Pick<PhoneValidResult, 'countryCode' | 'countryIso2' | 'countryIso3'>;

export interface EnvSettingsParameters {
  OC_URL: string;
  ELISARING_URL: string;
  MITEL_URL: string;
  ENREACH_VOICE_URL: string;
  GUIDE_URL: string;
  REPORTING_PAGE_URL: string;
  KNOWLEDGE_BASE_URL: string;
  MAX_SEARCH_RESULTS: number;
  FILTER_SYSTEM_EMAIL: string;
  TICKETLIST_INTERVAL: number;
  INFO_PAGE_LIST_INTERVAL: number;
  SANITIZATION: Sanitization;
  SOUND_NOTIFICATION_CHANNELS: soundChannels;
  CHATSANITIZATION: Sanitization;
  PRIORITY_MAIL_THRESHOLD_NOTIFICATION: number | null;
  SALESFORCE: { value: string; data: ISalesforceSettingsData };
  AI_ASSISTANT: { data: { name: string; iconName: string } };
  OPEN_EXTERNAL_LINK: {
    data: {
      fieldName: string | string[];
      inBackground?: boolean;
      channel?: number | number[];
      direction?: string[];
    };
  };
  CALLS_TO_SAME_TICKET: {
    data: {
      taskStatus?: string[];
      sameTicketType?: boolean;
      sameDirection?: boolean;
    };
  };
  AUTOMATIC_CLOSING_TICKETS: { data: { delay: number; channel: number[]; status: string[] } };
  GOOGLE_API_KEY: string;
  LOCALIZATION: Localization;
  DEFAULT_SEARCH: {
    value: string | null;
    data: {
      property: string;
      value: string | number;
      type: 'string' | 'date' | 'boolean' | 'user' | 'tag' | 'direction' | 'channel' | 'category';
      dateShift?: DateShiftConfig;
    }[];
  };
  ENREACH_VOICE_VERSION?: string;
}

export interface DateShiftConfig {
  operator: 'add' | 'subtract';
  amount: number;
  unit: 'day' | 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond';
}

export default class EnvSettings {
  private static settings: EnvSettingsParameters = {
    OC_URL: '',
    ELISARING_URL: '',
    MITEL_URL: '',
    ENREACH_VOICE_URL: '',
    GUIDE_URL: '',
    REPORTING_PAGE_URL: '',
    KNOWLEDGE_BASE_URL: '',
    MAX_SEARCH_RESULTS: 1000,
    FILTER_SYSTEM_EMAIL: 'true',
    TICKETLIST_INTERVAL: 30000,
    INFO_PAGE_LIST_INTERVAL: 500,
    PRIORITY_MAIL_THRESHOLD_NOTIFICATION: null,
    SOUND_NOTIFICATION_CHANNELS: { data: { channels: [] } },
    OPEN_EXTERNAL_LINK: { data: { fieldName: '' } },
    CALLS_TO_SAME_TICKET: { data: {} },
    AUTOMATIC_CLOSING_TICKETS: { data: { delay: 15, channel: [], status: ['doing'] } },
    GOOGLE_API_KEY: '',
    AI_ASSISTANT: { data: { name: '', iconName: '' } },
    SALESFORCE: {
      value: '',
      data: {
        hiddenFields: [],
        taskStatuses: [],
        leadStatuses: [],
        opportunityStages: [],
        contactFieldsToFetch: [],
        accountFieldsToFetch: [],
        userFieldsToFetch: [],
        contactFieldView: '',
        accountFieldView: '',
        userFieldView: '',
        taskDefaultOwnerId: '',
        leadDefaultOwnerId: '',
        opportunityDefaultOwnerId: '',
        leadDefaultStatus: '',
        taskDefaultStatus: '',
        opportunityDefaultStage: '',
        fieldsMapping: {
          case: {},
          task: {},
          lead: {},
          opportunity: {}
        },
        closeDateInSeconds: true,
        dueDateInSeconds: true
      }
    },
    CHATSANITIZATION: {
      value: 'chatComment',
      data: {
        allowedTags: [],
        selfClosing: [],
        allowedSchemes: [],
        allowedAttributes: {},
        disallowedTagsMode: 'discard'
      }
    },
    SANITIZATION: {
      value: 'userComment',
      data: {
        allowedTags: [
          'address',
          'article',
          'aside',
          'footer',
          'header',
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'hgroup',
          'main',
          'nav',
          'section',
          'blockquote',
          'dd',
          'div',
          'dl',
          'dt',
          'figcaption',
          'figure',
          'hr',
          'li',
          'main',
          'ol',
          'p',
          'pre',
          'ul',
          'abbr',
          'b',
          'bdi',
          'bdo',
          'br',
          'cite',
          'code',
          'data',
          'dfn',
          'em',
          'i',
          'kbd',
          'mark',
          'q',
          'rb',
          'rp',
          'rt',
          'rtc',
          'ruby',
          's',
          'samp',
          'small',
          'span',
          'strong',
          'sub',
          'sup',
          'time',
          'u',
          'var',
          'wbr',
          'caption',
          'col',
          'colgroup',
          'table',
          'tbody',
          'td',
          'tfoot',
          'th',
          'thead',
          'tr'
        ],
        selfClosing: ['br', 'hr'],
        allowedSchemes: [],
        allowedAttributes: {},
        disallowedTagsMode: 'escape',
        allowProtocolRelative: true,
        allowedSchemesAppliedToAttributes: []
      }
    },
    LOCALIZATION: {
      countryIso2: '',
      countryIso3: '',
      countryCode: ''
    },
    DEFAULT_SEARCH: {
      value: null,
      data: []
    }
  };

  static setSettings(incomingSettings: EnvSettingsParameters) {
    this.settings = {
      ...this.settings,
      ...incomingSettings
    };
  }

  static getSettings() {
    return this.settings;
  }
}

import iziToast from 'izitoast';
import { sortBy } from 'lodash-es';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Accordion, Checkbox, Divider, Header, Icon, Segment } from 'semantic-ui-react';

import type { TFunction } from 'i18next';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { SemanticICONS } from 'semantic-ui-react';

import { getContentStatusOptions } from '../Case/contentStatuses';
import { timestampPrefixToDate } from '../Case/DateSelector';
import Info from '../Case/Info/Info';
import SearchConditionList from '../Search/SearchConditionList';
import SearchControls from '../Search/SearchControls';
import { SearchDateRanges } from '../Search/SearchDateRanges';
import { SearchEntitiesAccordion } from './SearchEntitiesAccordion';
import TextSearch from './TextSearch';
import { activateContentListTab, addContentListTab, setContentListSearch } from 'src/actions/CaseListActions';
import { fetchInfoPages } from 'src/actions/infoPagesActions';
import { fetchTickets } from 'src/actions/ticketsActions';
import EnvSettings from 'src/api/EnvSettings';
import FeatureFlags from 'src/api/FeatureFlags';
import { TicketDirectionValues } from 'src/types/Ticket';
import { StaticTabs } from 'src/types/TicketList';
import { getPrettyDate } from 'src/Utilities/dates';
import { formatSearch, getSearchFieldTranslation, prepareDateDefaultFieldvalue } from 'src/Utilities/search';
import { filterTagsShownToUser } from 'src/Utilities/tags';

import type { TicketStatuses } from '../Case/contentStatuses';
import type { Category } from 'src/types/Category';
import type { Channel } from 'src/types/Channel';
import type { TabFilter } from 'src/types/Filter';
import type { Field } from 'src/types/Info';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { FormattedSearch, SearchCriterion } from 'src/types/Search';
import type { Tag } from 'src/types/Tag';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';

interface SearchProps {
  contentType: ContentTypesFields;
  ticketTypes: TicketType[];
  channels: Channel[];
  tags: Tag[];
  tagCategories: Category[];
  users: User[];
  personalData: PersonalData;
  searchCriteria: SearchCriterion[];
  tabId: string;
  language: string;
  noDefaultDates?: boolean;

  onSubmit: (filter: FormattedSearch, id: string, searchParams: any[]) => Promise<any>;
  closePane?: () => void;
  t: TFunction;
}

interface SearchState {
  tags: Tag[];
  selectedTicketWords: string;
  titleSearch: string;
  statuses: TicketStatuses[];
  originalContactSearch: string;
  entityId: string;
  emailCc: string;
  lastContactAddress: string;
  emailTo: string;
  emailFrom: string;
  searchToggleAndOr: boolean;
  searchCriteria: SearchCriterion[];
}

interface OwnProps {
  contentType: ContentTypesFields;
  closePane?: () => void;
  t: TFunction;
}

interface StateProps {
  ticketTypes: TicketType[];
  channels: Channel[];
  tags: Tag[];
  tagCategories: Category[];
  users: User[];
  personalData: PersonalData;
  tabId: string;
  searchCriteria: SearchCriterion[];
  language: string;
}

interface DispatchProps {
  onSubmit(filter: FormattedSearch, id: string, searchParams: any[]): Promise<any>;
}

type SearchField = Field & {
  value: keyof FormattedSearch['basic'] | keyof TabFilter;
  infoValue?: unknown;
};

interface ConditionalElement {
  conditional: boolean;
  element: () => JSX.Element;
}

type TextSearches = {
  [key: string]: (
    | [keyof Partial<SearchState>, string]
    | SearchField
    | ConditionalElement
    | SearchField[]
    | JSX.Element
    | null
  )[];
};

type TextSearchesAutocomplete = (keyof Partial<SearchState>)[];

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const tabs: MenuTab[] = {
    tickets: Object.values(state.ticketListTabs),
    infopages: [...state.infoPageListTabs.values()]
  }[ownProps.contentType];

  const activeTab = tabs.find((tab) => tab.activeTab)!;

  return {
    users: state.usersList.usersList,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    tabId: activeTab.id,
    searchCriteria: activeTab.searchCriteria,
    tags: state.tags,
    tagCategories: state.categories,
    personalData: state.userData,
    language: state.userData.language
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<State, void, AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    onSubmit: async (filter: FormattedSearch, id: string, searchCriteria: SearchCriterion[]) => {
      if (id === StaticTabs.MAIN_VIEW) {
        id = moment().unix().toString();
      }

      const types = filter?.basic.type as ContentTypesFields[];
      const type = types[0];

      dispatch(addContentListTab(id, 'TAB_NAME_SEARCH', type));
      dispatch(activateContentListTab(id, type));
      dispatch(setContentListSearch(id, searchCriteria, type));
      ownProps.closePane?.();

      switch (type) {
        case 'tickets':
          return dispatch(fetchTickets(filter, id, true));
        case 'infopages':
          return dispatch(fetchInfoPages(filter, id, true));
      }
    }
  };
}

class Search extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);

    this.state = {
      tags: [],
      selectedTicketWords: '',
      titleSearch: '',
      originalContactSearch: '',
      entityId: '',
      emailTo: '',
      statuses: [],
      emailFrom: '',
      emailCc: '',
      lastContactAddress: '',
      searchToggleAndOr: true,
      searchCriteria:
        props.searchCriteria && props.searchCriteria.length > 0 ? props.searchCriteria : this.getDefaultSearchCriteria()
    };
  }

  private getDefaultSearchCriteria = () => {
    const { t } = this.props;

    const { data: defaultSearchConfiguration } = EnvSettings.getSettings().DEFAULT_SEARCH;

    return defaultSearchConfiguration.reduce((accumulator, configItem) => {
      let value = configItem.value;
      let text = String(configItem.value);

      switch (configItem.type) {
        case 'date': {
          const timestamp = prepareDateDefaultFieldvalue({
            value: configItem.value,
            dateShiftConfig: configItem.dateShift
          });
          value = timestampPrefixToDate(timestamp);
          text = getPrettyDate(timestamp);
          break;
        }

        case 'user': {
          const user = this.props.users.find((user) => parseInt(user.UID.substring(3), 10) === configItem.value);
          text = `${user?.profile.firstName} ${user?.profile.lastName}`;
          break;
        }

        case 'tag': {
          text = this.props.tags.find((tag) => tag.id === configItem.value)?.name as string;
          break;
        }

        case 'direction': {
          const direction = TicketDirectionValues.find((direction) => direction === configItem.value);
          text = t(`search.direction.${direction}`);
          break;
        }

        case 'channel': {
          const singleChannel = this.props.channels.find(
            (channel) => channel.id === parseInt(configItem.value as string, 10)
          )!;
          text = t([`CHANNEL_${singleChannel.channel.toUpperCase()}`, singleChannel.channel]);
          break;
        }

        case 'category': {
          text = this.props.tagCategories.find((category) => category.id === parseInt(configItem.value as string, 10))
            ?.name as string;
          break;
        }

        default: {
          break;
        }
      }

      return [
        ...accumulator,
        {
          name: getSearchFieldTranslation(configItem.property, t),
          param: configItem.property,
          text,
          value,
          datagroup: 'basic',
          object: false
        }
      ];
    }, [] as SearchCriterion[]);
  };

  private onClear = () => {
    const { t } = this.props;
    iziToast.warning({ message: t('search.reset') });
    this.setState({
      searchCriteria: []
    });
  };

  private onSubmit = () => {
    const { t } = this.props;
    iziToast.info({
      message: t('search.in_progress'),
      displayMode: 1,
      id: 'searchToast',
      target: '.toastSearchTarget'
    });

    const searchParams = formatSearch(this.state.searchCriteria, this.props.personalData.UID);
    searchParams.basic.type = [this.props.contentType];
    this.props.onSubmit(searchParams, this.props.tabId, this.state.searchCriteria);
  };

  private onSave = (
    param: keyof FormattedSearch['basic'],
    value: string | boolean | Tag[] | null | undefined,
    object: any,
    partial: boolean,
    name: string,
    text?: string,
    datagroup?: string,
    entityType?: string
  ) => {
    if (datagroup === undefined) {
      datagroup = 'basic';
    }
    if (param === 'selectedTicketWords') {
      this.setState({
        tags: value as Tag[]
      });
    }
    if (param === 'searchToggleAndOr') {
      this.setState({
        searchToggleAndOr: value as boolean
      });
    }

    this.setState(
      (previousState: SearchState) => {
        const newSearchCriteria = [...previousState.searchCriteria];
        const oldSearchCriterionIndex = newSearchCriteria.findIndex((criterion) => {
          return criterion.param === param;
        });

        const oldSearchCriterion = newSearchCriteria[oldSearchCriterionIndex];
        if (value == null || value === '' || value === false) {
          const nonDeleteParams = [
            'selectedTicketTagsAnd',
            'selectedTicketTagsOr',
            'selectedTicketTagsNot',
            'selectedTicketTagCategoriesOr'
          ];
          if (oldSearchCriterion && !nonDeleteParams.includes(param)) {
            this.onDelete(oldSearchCriterion);
          }

          return previousState;
        }

        if (oldSearchCriterion) {
          const multipleSearchParams: (keyof FormattedSearch['basic'])[] = [
            'ticketTypesOr',
            'contactChannel',
            'selectedTicketTagsAnd',
            'selectedTicketTagsOr',
            'selectedTicketTagsNot',
            'statuses',
            'selectedTicketTagCategoriesOr',
            'workedByUsers',
            'delegatedTo'
          ];
          if (multipleSearchParams.includes(param)) {
            newSearchCriteria.push({
              param,
              value,
              text,
              name,
              datagroup,
              object
            });
          } else {
            const fallbackCriteria = { ...oldSearchCriterion };
            fallbackCriteria.value = value;
            fallbackCriteria.text = text ? text : String(value);
            newSearchCriteria[oldSearchCriterionIndex] = fallbackCriteria;
          }
        } else {
          newSearchCriteria.push({
            param,
            value,
            text: text ? text : String(value),
            name,
            datagroup,
            object
          });
        }

        return {
          searchCriteria: newSearchCriteria
        };
      },
      () => {
        if (entityType) {
          this.onSubmit();
        }
      }
    );
  };

  private onDelete = (item: SearchCriterion) => {
    this.setState((previousState) => ({
      searchCriteria: previousState.searchCriteria.filter((criterion) => criterion !== item)
    }));
  };

  private translateSearchCriteriaToFieldValues = (searchCriteria: Array<SearchCriterion>) => {
    const fieldValues = {};
    searchCriteria.forEach((searchCriterion) => {
      fieldValues[searchCriterion.param] = searchCriterion.value;
    });
    return fieldValues;
  };

  // TODO make the whole component use hooks
  SearchPanel = (props: SearchProps) => {
    const { t } = this.props;
    const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({
      'search.terms': true,
      'search.basic_data': true
    });

    const isExclusiveSearchChecked =
      this.state.searchCriteria.find((criteria) => {
        return criteria.param === 'searchToggleAndOr';
      }) != null;

    const ticketTypes = sortBy(
      props.ticketTypes.map(
        (x) => ({
          name: x.name,
          value: x.name
        }),
        'value'
      )
    );

    const channels = props.channels
      .filter((channel) => channel.active && props.personalData.channels.includes(channel.id))
      .map((x) => ({
        name: t([`CHANNEL_${x.channel.toUpperCase()}`, x.channel]),
        value: x.id,
        icon: (x.icon || 'question circle') as SemanticICONS
      }));

    const tags = filterTagsShownToUser(props.tags, props.personalData.ticketTypes).map((x) => ({
      name: x.name,
      value: x.id
    }));

    const tagCategories = props.tagCategories.map((category) => ({
      name: category.name,
      value: category.id
    }));

    const users = props.users.map((user) => ({
      name: `${user.profile.firstName} ${user.profile.lastName}`,
      value: user.UID.substring(3)
    }));

    const statuses = getContentStatusOptions(this.props.contentType, t).map((x) => ({
      name: x.text,
      value: x.value,
      icon: x.icon as SemanticICONS
    }));

    const directions = TicketDirectionValues.map((dir) => ({
      name: t(`search.direction.${dir}`),
      value: dir
    }));

    const criteriaTicketTypes: string[] = this.state.searchCriteria
      .filter((c) => c.param === 'ticketTypesOr')
      ?.map((c) => c.value);
    const filteredTicketTypes = props.ticketTypes.filter((type) => criteriaTicketTypes.includes(type.name));
    if (filteredTicketTypes.length === 0) {
      const defaultType = props.ticketTypes.find((type) => props.personalData.ticketTypes.includes(type.id))!;
      filteredTicketTypes.push(defaultType);
    }

    const searches: TextSearches = {
      'search.basic_data': [
        ['titleSearch', getSearchFieldTranslation('titleSearch', t)],
        ['selectedTicketWords', getSearchFieldTranslation('selectedTicketWords', t)],
        FeatureFlags.isFlagOn('ENABLE_SEARCH_ENTITY_ID')
          ? ['entityId', getSearchFieldTranslation('entityId', t)]
          : null,
        [
          {
            name: getSearchFieldTranslation('ticketTypesOr', t),
            value: 'ticketTypesOr',
            options: ticketTypes
          } as any,
          {
            name: getSearchFieldTranslation('contactChannel', t),
            value: 'contactChannel',
            options: channels
          },
          {
            name: getSearchFieldTranslation('selectedTicketTagsAnd', t),
            value: 'selectedTicketTagsAnd',
            options: tags,
            infoValue: undefined
          },
          {
            name: getSearchFieldTranslation('selectedTicketTagsOr', t),
            value: 'selectedTicketTagsOr',
            options: tags,
            infoValue: undefined
          },
          {
            name: getSearchFieldTranslation('selectedTicketTagsNot', t),
            value: 'selectedTicketTagsNot',
            options: tags,
            infoValue: undefined
          },
          {
            name: getSearchFieldTranslation('statuses', t),
            value: 'statuses',
            options: statuses
          },
          {
            name: getSearchFieldTranslation('selectedTicketTagCategoriesOr', t),
            value: 'selectedTicketTagCategoriesOr',
            options: tagCategories,
            infoValue: undefined
          }
        ],
        {
          conditional: FeatureFlags.isFlagOn('ENABLE_EXCLUSIVE_SEARCH'),
          element: () => (
            <Checkbox
              toggle={true}
              style={{ marginTop: '13px' }}
              checked={isExclusiveSearchChecked}
              onChange={(_, data) => {
                this.onSave(
                  'searchToggleAndOr',
                  data.checked,
                  false,
                  true,
                  '',
                  getSearchFieldTranslation('searchToggleAndOr', t),
                  'basic'
                );
              }}
              label={getSearchFieldTranslation('searchToggleAndOr', t)}
            />
          )
        }
      ],
      'search.dates': [
        <SearchDateRanges
          noDefaultDates={this.props.noDefaultDates}
          values={this.state.searchCriteria}
          onChange={(date, timestamp) => {
            this.onSave(date, timestamp, undefined, false, getSearchFieldTranslation(date, t), timestamp, 'basic');
          }}
        />
      ],
      'search.addresses': [
        ['originalContactSearch', getSearchFieldTranslation('originalContactSearch', t)],
        ['lastContactAddress', getSearchFieldTranslation('lastContactAddress', t)],
        ['emailTo', getSearchFieldTranslation('emailTo', t)],
        ['emailFrom', getSearchFieldTranslation('emailFrom', t)],
        ['emailCc', getSearchFieldTranslation('emailCc', t)]
      ],
      'search.ticket.details': [
        [
          {
            name: getSearchFieldTranslation('ticketAuthorId', t),
            value: 'ticketAuthorId',
            options: users
          },
          {
            name: getSearchFieldTranslation('ticketEditedById', t),
            value: 'ticketEditedById',
            options: users
          },
          {
            name: getSearchFieldTranslation('showOnlyNotReady', t),
            value: 'showOnlyNotReady',
            switch: [
              { name: t('YES'), value: true },
              { name: t('NO'), value: false }
            ]
          },
          {
            name: getSearchFieldTranslation('workedBy', t),
            value: 'workedBy',
            switch: [
              { name: t('YES'), value: true },
              { name: t('NO'), value: false }
            ]
          },
          {
            name: getSearchFieldTranslation('delegatedToMe', t),
            value: 'delegatedToMe',
            switch: [
              { name: t('YES'), value: true },
              { name: t('NO'), value: false }
            ]
          },
          {
            name: getSearchFieldTranslation('workedByUsers', t),
            value: 'workedByUsers',
            options: users,
            infoValue: undefined
          },
          {
            name: getSearchFieldTranslation('delegatedTo', t),
            value: 'delegatedTo',
            options: users,
            infoValue: undefined
          },
          {
            name: getSearchFieldTranslation('originalDirection', t),
            value: 'originalDirection',
            options: directions
          }
        ]
      ],
      'search.entities': [
        // TODO special parameters passed to Info.tsx
        <SearchEntitiesAccordion
          ticketTypes={filteredTicketTypes}
          defaultTicketType={props.personalData.userPreferences.defaultTicketType}
          onSave={this.onSave}
          criteriaToFieldValues={this.translateSearchCriteriaToFieldValues}
          criteria={this.state.searchCriteria}
        />
      ]
    };

    const autocompleteSearches: TextSearchesAutocomplete = ['emailTo', 'emailFrom', 'emailCc', 'lastContactAddress'];

    return (
      <Segment className="searchComponent">
        <SearchControls onSubmit={this.onSubmit} onClear={this.onClear} />
        <Divider />
        <Accordion>
          <Accordion.Title
            active={openAccordions['search.terms']}
            onClick={() =>
              setOpenAccordions((old) => ({
                ...old,
                'search.terms': !old['search.terms']
              }))
            }
          >
            <Header as="h4">
              <Icon name="dropdown" />
              {`${t('search.terms')} (${this.state.searchCriteria.length})`}
            </Header>
          </Accordion.Title>
          <Accordion.Content
            active={openAccordions['search.terms']}
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
          >
            <SearchConditionList size="large" items={this.state.searchCriteria} onDelete={this.onDelete} />
          </Accordion.Content>
        </Accordion>
        {Object.entries(searches).map((search) => {
          const [key, value] = search;

          return (
            <Accordion>
              <Accordion.Title
                active={openAccordions[key]}
                onClick={() =>
                  setOpenAccordions((old) => ({
                    ...old,
                    [key]: !old[key]
                  }))
                }
              >
                <Header as="h4">
                  <Icon name="dropdown" />
                  {t(key)}
                </Header>
              </Accordion.Title>
              <Accordion.Content active={openAccordions[key]}>
                {value.map((field) => {
                  if (field === null) {
                    return null;
                  }

                  if (Array.isArray(field)) {
                    if (typeof field[0] === 'string') {
                      field = field as [keyof SearchState, string];
                      const key = field[0];
                      const name = field[1];
                      const autocomplete = autocompleteSearches.includes(key);

                      return (
                        <TextSearch
                          id={key as keyof FormattedSearch['basic']} // TODO typing
                          name={name}
                          onSave={this.onSave.bind(this)}
                          onSet={(value) => {
                            // Check TextSearches type
                            this.setState({ [key]: value } as any);
                          }}
                          autocomplete={autocomplete}
                        />
                      );
                    } else {
                      field = field as SearchField[];
                      const values = field.reduce((obj, f) => {
                        obj[f.value] = f.infoValue;
                        return obj;
                      }, {});

                      return (
                        <Info
                          fields={field}
                          values={values}
                          onSave={this.onSave}
                          params={{ ignoreFieldDisabling: true }}
                          language={props.language}
                        />
                      );
                    }
                  }

                  if ('conditional' in field && 'element' in field) {
                    if (!field.conditional) {
                      return <></>;
                    }

                    return field.element();
                  }

                  if ('value' in field && 'infoValue' in field) {
                    return (
                      <Info
                        fields={[field]}
                        values={{ [field.value]: field.infoValue }}
                        onSave={this.onSave}
                        params={{ ignoreFieldDisabling: true }}
                        language={props.language}
                      />
                    );
                  }

                  return field;
                })}
              </Accordion.Content>
            </Accordion>
          );
        })}
      </Segment>
    );
  };

  render() {
    return <this.SearchPanel {...this.props} />;
  }
}

export default withTranslation('translations')(
  connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps)(Search)
);

import classNames from 'classnames';
import { t } from 'i18next';
import iziToast from 'izitoast';
import React from 'react';
import { Accordion } from 'semantic-ui-react';

import type { History } from 'history';

import styles from './ElisaOC.module.css';
import { getRetryableFunction } from './utils/retry';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import {
  findPhoneConfiguration,
  getIntegrationData,
  parsePhoneConfiguration,
  shouldCreateAPhoneCallTicket
} from 'src/Components/PhoneServices/utils/phoneConfigurationUtils';
import { convertCaseNaming } from 'src/Utilities/helper';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';

import type { CallRequestPayload } from 'src/actions/phoneActions';
import type { IntegrationData, UpdateDetailsObject } from 'src/handlers/handlePhoneCall';
import type { PhoneConfiguration } from 'src/types/PhoneConfiguration';
import type { TicketDirection } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData } from 'src/types/User';

interface ElisaOCProps {
  ticketTypes: TicketType[];
  url: string;
  history: History;
  userData: PersonalData;
  configurations: PhoneConfiguration[];
  callRequests: CallRequestPayload[];
  isOpen: boolean;

  setIsOpen: (active: boolean) => void;
  removeCallRequest: (ticketId: number, phoneNumber: string) => void;
  handleIncomingPhoneCall: (callObject: IntegrationData) => Promise<string>;
}

interface ElisaOCState {
  lastOCTicketId: null | string;
  lastServiceName: null | string;
  lastPhoneNumber: null | string;
  direction: null | TicketDirection;
  caseId: null | string;
}

interface OCContactDetailsPayload {
  ticketId: string;
  serviceName: string;
  data: {
    direction: string;
    contactInfo: string;
  };
}

interface OCContactMetaDataPayload {
  ticketId: string;
  metadata: { [key: string]: unknown };
}

class ElisaOCComponent extends React.Component<ElisaOCProps, ElisaOCState> {
  constructor(props: ElisaOCProps) {
    super(props);

    this.state = {
      lastOCTicketId: null,
      lastServiceName: null,
      lastPhoneNumber: null,
      direction: null,
      caseId: null
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleElisaEvents);

    window['__testElisaOCEvent'] = (
      phoneNumber: string | number,
      direction?: TicketDirection,
      serviceName?: string
    ) => {
      const normalizedPhoneNumber = normalizePhoneNumber(
        typeof phoneNumber === 'number' ? phoneNumber.toString() : phoneNumber
      );
      const eData = {
        type: 'ocContactDetails',
        payload: {
          ticketId: 'test',
          serviceName: serviceName || 'test',
          data: {
            direction: direction === 'out' ? 'Contact.Out' : 'Contact.In',
            contactInfo: normalizedPhoneNumber,
            startPathName: '',
            contactType: 'Manual'
          }
        }
      };
      const event = new MessageEvent('message', {
        data: eData
      });
      window.dispatchEvent(event);
    };

    window['__testElisaOC'] = (phoneNumber: string, serviceName?: string, ocTicketId?: string) => {
      if (!phoneNumber) {
        console.error('Please specify phoneNumber');
        return;
      }
      const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
      this.setState(
        {
          lastPhoneNumber: normalizedPhoneNumber,
          lastOCTicketId: ocTicketId || 'test',
          lastServiceName: serviceName || 'test'
        },
        () => {
          this.handleIncomingPhoneCall();
        }
      );
    };

    window['__testElisaOCMetaData'] = (ocTicketId?: string, metadata?: Record<string, unknown>) => {
      const eData = {
        type: 'ocContactMetaData',
        payload: {
          ticketId: ocTicketId,
          metadata: metadata ?? {}
        } as OCContactMetaDataPayload
      };
      const event = new MessageEvent('message', { data: eData });
      window.dispatchEvent(event);
    };
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleElisaEvents);
    delete window['__testElisaOC'];
    delete window['__testElisaOCEvent'];
    delete window['__testElisaOCMetaData'];
  }

  private findCallRequest = (normalizedPhoneNumber: string) => {
    return this.props.callRequests.find(({ phoneNumber, fromClickToCall }) => {
      // format numbers so they match!
      const callRequestNumber = normalizePhoneNumber(phoneNumber);

      return fromClickToCall && callRequestNumber === normalizedPhoneNumber;
    });
  };

  private handleOutgoingPhoneCall = (normalizedPhoneNumber: string, ticketId: number) => {
    this.props.removeCallRequest(ticketId, normalizedPhoneNumber);
  };

  private getCaseIdFromStateOrApi = async (ocTicketId: string): Promise<string | undefined | null> => {
    const getCaseIdFromState = () => {
      let { caseId } = this.state;
      if (!caseId) {
        throw new Error('No case id in state!');
      }
      return caseId;
    };
    const fnWithRetry = getRetryableFunction(getCaseIdFromState, 3);
    try {
      return await fnWithRetry();
    } catch (err) {
      console.warn('Could not get caseId from local state');
      return (await TicketsApi.searchContentDetails({ ocTicketId })).shift()?.caseId;
    }
  };

  private handleElisaEvents = async (event: MessageEvent) => {
    if (!event.data?.type) {
      return;
    }

    switch (event.data.type) {
      case 'ocContactDetails': {
        const payload: OCContactDetailsPayload = event.data.payload;
        const { ticketId, serviceName, data } = payload;
        const commentDirection = data.direction === 'Contact.Out' ? 'out' : 'in';
        const phoneNumber = data.contactInfo;
        const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);

        const callRequest = this.findCallRequest(normalizedPhoneNumber);

        this.setState(
          {
            lastOCTicketId: ticketId,
            lastPhoneNumber: phoneNumber,
            lastServiceName: serviceName,
            direction: commentDirection,
            caseId: null
          },
          () => {
            if (!callRequest) {
              // if event is NOT found in callRequests -> create new ticket
              this.handleIncomingPhoneCall();
            } else {
              // if event IS found in callRequests -> add outward call comment to that ticket
              this.handleOutgoingPhoneCall(normalizedPhoneNumber, callRequest.ticketId);
            }
          }
        );
        break;
      }

      case 'ocContactMetaData': {
        const payload: OCContactMetaDataPayload = event.data.payload;
        const { ticketId, metadata } = payload;

        const caseId = await this.getCaseIdFromStateOrApi(ticketId);
        if (caseId) {
          await TicketsApi.updateTicketDetails(
            convertCaseNaming(caseId, 'string') as string,
            'CaseDetails',
            Object.keys(metadata).map((key) => ({
              type: `OC_META_${key}`,
              value: metadata[key]
            }))
          );
        } else {
          console.warn("[ElisaOC]: can't set ocContactMetaData - no ocTicketId found. ocTicketId: ", ticketId);
        }
        break;
      }

      default:
        // do nothing..
        break;
    }
  };

  private handleIncomingPhoneCall = async () => {
    if (
      this.state.lastServiceName === null ||
      this.state.lastOCTicketId === null ||
      this.state.lastPhoneNumber === null
    ) {
      return;
    }

    const normalizedPhoneNumber = normalizePhoneNumber(this.state.lastPhoneNumber);
    const direction = this.state.direction === 'out' ? 'out' : 'in';
    const configuration = findPhoneConfiguration(this.props.configurations, this.state.lastServiceName);
    const parsedConfiguration = parsePhoneConfiguration(configuration);

    const UID = this.props.userData.UID;
    const history = this.props.history;

    const detailsObjects: UpdateDetailsObject[] = [
      {
        updateKey: 'ocTicketId',
        updateValue: this.state.lastOCTicketId,
        group: 'CaseDetails'
      }
    ];

    if (this.state.lastServiceName !== null) {
      detailsObjects.push({
        updateKey: 'ocServiceName',
        updateValue: this.state.lastServiceName,
        group: 'CaseDetails'
      });
    }

    const callObject = getIntegrationData({
      UID,
      configuration,
      detailsObjects,
      direction,
      history,
      normalizedPhoneNumber,
      parsedConfiguration,
      serviceName: this.state.lastServiceName,
      ticketTypes: this.props.ticketTypes,
      userDefaultTicketTypeId: this.props.userData.userPreferences.defaultTicketType
    });

    if (FeatureFlags.isFlagOn('ON_CALL_OPEN_CALL_UI')) {
      this.props.setIsOpen(true);
    }

    if (shouldCreateAPhoneCallTicket(configuration, parsedConfiguration)) {
      // When no configuration is found, or when configuration does not prevent creation
      const caseId: string = await this.props.handleIncomingPhoneCall(callObject);
      this.setState({ caseId });
    } else {
      iziToast.error({
        message: t('PHONECONFIGURATION_NO_TICKET'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
    }

    this.setState({
      lastServiceName: null,
      lastPhoneNumber: null,
      lastOCTicketId: null,
      direction: null
    });
  };

  private toggleAccordion = () => {
    this.props.setIsOpen(!this.props.isOpen);
  };

  private shouldShowMinimizedVersion = () => {
    return FeatureFlags.isFlagOn('ENABLE_ELISAOC_SHOW_STATUS_WHEN_MINIMIZED');
  };

  render() {
    return (
      <>
        <Accordion.Title className="ticketlist ticketlist_OC" active={this.props.isOpen} onClick={this.toggleAccordion}>
          Elisa OC
        </Accordion.Title>
        <Accordion.Content
          active={this.props.isOpen || this.shouldShowMinimizedVersion()}
          className={classNames(!this.props.isOpen && styles.minimized)}
        >
          <div>
            <iframe
              title="ElisaOC"
              id="ElisaOC"
              scrolling="yes"
              style={{
                width: '100%',
                height: '50vh',
                flex: '1 1 auto',
                display: 'flex'
              }}
              src={this.props.url}
              frameBorder={0}
              allowFullScreen={false}
            />
          </div>
        </Accordion.Content>
      </>
    );
  }
}

export default ElisaOCComponent;

import { t as tr } from 'i18next';
import iziToast from 'izitoast';
import { cloneDeep } from 'lodash-es';
import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';

import type { TFunction } from 'i18next';
import type { IziToast } from 'izitoast';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import type { SemanticICONS } from 'semantic-ui-react';

import { activateTab } from 'src/actions/tabActionsRTK';
import { updateTicket } from 'src/actions/ticketsActions';
import { removeTicketFromTicketlist } from 'src/actions/ticketsActionsRTK';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import TicketStatusDropdown from 'src/Components/generic/TicketStatusDropdown';
import TicketTypeDropdown from 'src/Components/generic/TicketTypeDropdown';
import EnreachVoiceButtons from 'src/Components/PhoneServices/EnreachVoiceButtons';
import ShortcutsStatusButtons from 'src/Components/Shortcuts/ShortcutsStatusButtons';
import CloseAndReturnAsDoing from 'src/containers/CloseAndReturnAsDoingContainer';
import WorkStatusHandlerContainer from 'src/containers/WorkStatusHandlerContainer';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';
import SocketInstance from 'src/realTimeNotifications';
import { typeToPrefix } from 'src/types/ContentTypes';
import { StaticTabs } from 'src/types/TicketList';
import { checkMandatoryFieldsForTicketClosing } from 'src/Utilities/restrictions';
import { replaceWorkingOn, startWorkingOn, stopWorkingOn } from 'src/Utilities/workStatusParser';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { StatusTypes, Ticket } from 'src/types/Ticket';
import type { User } from 'src/types/User';

import './TopBarStatusButtons.css';

interface OwnProps {
  contentType: ContentTypes;
  statusOptions: {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[];
}

export type TopBarStatusButtonsProps = TopBarStatusButtonsHOCProps;

interface TopBarState {
  isMandatoryFieldRequirementModalOpen: boolean;
  unfilledMandatoryFields: string[];
  user: User | undefined;
  ticketTypeNames: { text: string; value: string; key: string }[];
}

class TopBarStatusButtons extends React.Component<TopBarStatusButtonsProps, TopBarState> {
  constructor(props: TopBarStatusButtonsProps) {
    super(props);

    this.state = {
      isMandatoryFieldRequirementModalOpen: false,
      unfilledMandatoryFields: [],
      user: props.users.find((user) => user.UID === props.task.createdByUser.trim()),
      ticketTypeNames: []
    };

    this.setTicketTypeNames(props);
  }

  componentWillReceiveProps(nextProps: TopBarStatusButtonsProps) {
    this.setState({
      user: nextProps.users.find((user: User) => user.UID === nextProps.task.createdByUser.trim())
    });
    this.setTicketTypeNames(nextProps);
    this.defineUserElements(nextProps);
  }

  private onStartWorking = (UID: string) => {
    TicketsApi.startWorkingOn(...startWorkingOn(UID, this.props.task.id));
    this.handleStatusChange('doing');
  };

  private onStopWorking = (UID: string) => {
    this.props.stopWorkingOn(this.props.task.id, UID);
  };

  private onReplaceWorking = (oldUID: string, newUID: string) => {
    oldUID = 'USR' + oldUID;
    TicketsApi.replaceWorkingOn(...replaceWorkingOn(this.props.task.id, oldUID, newUID));
  };

  private getMandatoryFieldRequirementModal = (translation: TFunction) => {
    return (
      <Modal
        open={this.state.isMandatoryFieldRequirementModalOpen}
        onClose={() => this.setState({ isMandatoryFieldRequirementModalOpen: false })}
      >
        <Modal.Header>{translation('MANDATORY_FIELD_REQUIREMENT_TITLE')}</Modal.Header>
        <Modal.Content>
          {translation('MANDATORY_FIELD_REQUIREMENT_CONTENT')}
          <ul>
            {this.state.unfilledMandatoryFields.map((field) => {
              return <li>{translation(field)}</li>;
            })}
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            icon
            onClick={() => this.setState({ isMandatoryFieldRequirementModalOpen: false })}
            labelPosition="left"
          >
            <Icon name="check" />
            {translation('GENERAL_CLOSE')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  private setTicketTypeNames = (props: TopBarStatusButtonsProps) => {
    let metadata = props.ticketTypesMetadata;

    if (typeof metadata !== 'undefined') {
      // TODO MONGODB Remove type.canAdd === undefined when we stop using mongo
      metadata = metadata.filter(
        (type) => type.canAdd === undefined || type.canAdd || type.name === props.task.taskType
      );

      const ticketTypeNames = metadata
        .filter((type) => type.allowed)
        .map((type, index) => ({
          text: type.name,
          value: type.name,
          key: `${type.name}-${index}`,
          // TODO MONGODB Remove type.canAdd !== undefined when we stop using mongo
          disabled: type.canAdd !== undefined && !type.canAdd
        }));

      if (FeatureFlags.isFlagOn('ALLOW_CHANGING_TO_FORBIDDEN_TICKET_TYPES')) {
        const ticketTypeNamesForbidden = metadata
          .filter((type) => !type.allowed)
          .map((type, index) => {
            return {
              text: type.name,
              value: type.name,
              key: `${type.name}-${index}`,
              icon: 'angle right',
              className: 'ticketTypeDropdownForbiddenTicketTypeName',
              // TODO MONGODB Remove type.canAdd !== undefined when we stop using mongo
              disabled: type.canAdd !== undefined && !type.canAdd
            };
          });

        this.setState({
          ticketTypeNames: [...ticketTypeNames, ...ticketTypeNamesForbidden]
        });
      } else {
        this.setState({
          ticketTypeNames: ticketTypeNames
        });
      }
    }
  };

  // TODO: refactor to simply return userProfileLabel/userProfileContent Element without storing it under class property
  private defineUserElements = (props: TopBarStatusButtonsProps) => {
    const { users, task } = this.props;
    if (!users?.length || !task) {
      return;
    }

    this.setState({
      user: props.users.find((user) => user.UID === props.task.createdByUser.trim())
    });
  };

  private handleStatusChange(newStatus: StatusTypes) {
    if (this.props.task.id !== 'NEW') {
      if (newStatus === 'done') {
        const task = cloneDeep(this.props.task);
        task.status = newStatus;

        if (this.checkCloseAsDoneRequirements(this.props.task, this.props.user.UID, true)) {
          return this.props.updateTicket(task.id, { status: newStatus });
        }
        return;
      }

      return this.props.updateTicket(this.props.task.id, { status: newStatus });
    }
  }

  private handleTypeChange = (typeToChangeTo: string) => {
    if (this.props.task) {
      if (
        this.props.ticketTypesMetadata.find((type) => {
          return type.name === typeToChangeTo && type.allowed === true;
        })
      ) {
        this.props.updateTicket(this.props.task.id, {
          taskType: typeToChangeTo
        });
      } else {
        iziToast.question({
          timeout: 0,
          close: false,
          overlay: true,
          id: 'question',
          zindex: 999,
          message: tr('TICKET_TYPE_IS_FORBIDDEN_AND_WILL_DISABLE_THE_TICKET'),
          position: 'center',
          buttons: [
            [
              `<button><b>${tr('GENERAL_ACCEPT')}</b></button>`,
              (instance, toast) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
                let activeId: string = StaticTabs.MAIN_VIEW;
                const tabs = this.props.tabs.filter((tab) => {
                  return tab.id !== this.props.task.id && tab.id.substring(0, 3) === typeToPrefix('task');
                });

                if (tabs.length > 0) {
                  activeId = tabs[tabs.length - 1].id;
                  this.props.history.push(`/case/${activeId}`);
                }

                this.props.closeAndUpdateTicketType({
                  UID: this.props.user.UID,
                  ticketId: this.props.task.id,
                  taskType: typeToChangeTo,
                  activeId
                });

                this.props.removeTicketFromTicketlist({ ticketId: this.props.task.id });
              },
              false
            ],
            [
              `<button>${tr('GENERAL_CANCEL')}</button>`,
              (instance: IziToast, toast: HTMLDivElement) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              },
              false
            ]
          ]
        });
      }
    }
  };

  private checkCloseAsDoneRequirements = (task: Ticket, UID: string, returnBoolean = false) => {
    const { fields, status } = checkMandatoryFieldsForTicketClosing(task, this.props.ticketTypes, this.props.tags);
    if (!returnBoolean && status === 'OK') {
      return this.props.closeAsDone(task.id, UID);
    } else if (returnBoolean && status === 'OK') {
      return true;
    } else {
      return this.setState({ unfilledMandatoryFields: fields, isMandatoryFieldRequirementModalOpen: true });
    }
  };

  render() {
    const { task, user } = this.props;
    if (!task || !user) {
      return null;
    }

    const isAjaxCallInProgress = this.props.ajaxCalls.some(
      (ajaxCall) => ajaxCall.name === 'UPDATE_TICKET' && ajaxCall.id === this.props.task.id
    );

    const isDisabledCloseAsDone =
      ((this.state.user === undefined || task.status === 'done') && this.props.workStatus === undefined) ||
      !this.props.userData.permissions.includes('updateContent');

    return (
      <Translation ns="translations">
        {(tr) => (
          <Grid.Column className="statusButtonsDropdownsColumn" width={6} key="column">
            {this.getMandatoryFieldRequirementModal(tr)}

            <div className="topBarWorkStatusContainer">
              <WorkStatusImageContainer
                user={this.props.user}
                id={this.props.task.id}
                showStartWorkingOnButton={false}
                userData={this.props.userData}
              />
              <Grid stackable className="topBarWorkStatusHandlers">
                {this.props.contentType === 'task' && (
                  <>
                    <Grid.Row columns={2}>
                      <Grid.Column className="topBarButtonColumn">
                        <WorkStatusHandlerContainer
                          user={this.props.user}
                          caseId={task.id}
                          onStartWorking={this.onStartWorking}
                          onStopWorking={this.onStopWorking}
                          onReplaceWorking={this.onReplaceWorking}
                          userData={this.props.userData}
                        />
                      </Grid.Column>

                      <Grid.Column className="topBarButtonColumn">
                        <Button
                          style={{ width: '100%' }}
                          positive={true}
                          id="closeTicketBtn"
                          className="topBarStopTicketWorkingBtn"
                          labelPosition="left"
                          disabled={isDisabledCloseAsDone}
                          icon={true}
                          onClick={() => this.checkCloseAsDoneRequirements(this.props.task, this.props.user.UID)}
                        >
                          <Icon name="check" />
                          {tr('CLOSE_AS_DONE')}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      {FeatureFlags.isFlagOn('ENABLE_CHANGE_STATUS_TO_DOING') && (
                        <Grid.Column className="topBarButtonColumn">
                          <CloseAndReturnAsDoing
                            closeAndReturnAsDoing={() =>
                              this.props.closeAndReturnAsDoing(this.props.task.id, this.props.user.UID)
                            }
                          />
                        </Grid.Column>
                      )}

                      {FeatureFlags.isFlagOn('ENABLE_RETURN_AS_NEW') && (
                        <Grid.Column className="topBarButtonColumn">
                          <Button
                            style={{ width: '100%' }}
                            primary={true}
                            labelPosition="left"
                            disabled={
                              ((this.state.user === undefined || task.status === 'todo') &&
                                this.props.workStatus === undefined) ||
                              !this.props.userData.permissions.includes('updateContent')
                            }
                            icon={true}
                            onClick={() => this.props.returnAsNew(this.props.task.id, this.props.user.UID)}
                          >
                            <Icon name="level up alternate" />
                            {tr('RETURN_AS_NEW')}
                          </Button>
                        </Grid.Column>
                      )}
                    </Grid.Row>

                    <EnreachVoiceButtons />
                  </>
                )}

                <Grid.Row columns={2}>
                  <Grid.Column className="topBarButtonColumn">
                    <TicketStatusDropdown
                      disabled={isAjaxCallInProgress || !this.props.userData.permissions.includes('updateContent')}
                      value={task.status}
                      handleStatusChange={(value: string) => {
                        this.handleStatusChange(value as StatusTypes);
                      }}
                      loading={isAjaxCallInProgress}
                      statusOptions={this.props.statusOptions}
                    />
                  </Grid.Column>

                  <Grid.Column className="topBarButtonColumn">
                    <TicketTypeDropdown
                      value={task.taskType}
                      loading={isAjaxCallInProgress}
                      disabled={isAjaxCallInProgress || !this.props.userData.permissions.includes('updateContent')}
                      handleTypeChange={(value: string) => {
                        this.handleTypeChange(value);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>

            <ShortcutsStatusButtons
              onCloseAsDone={() =>
                !isDisabledCloseAsDone && this.checkCloseAsDoneRequirements(this.props.task, this.props.user.UID)
              }
            />
          </Grid.Column>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const task = state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!;
  return {
    task,
    tags: state.tags,
    ajaxCalls: state.ajaxCallsInProgress,
    mobileMode: state.mobileReducer.mobileMode,
    workStatus: state.workStatus.status.find((status) => {
      if (!task) {
        return false;
      }
      return 'TSK' + status.ticketId === task.id;
    }),
    users: state.usersList.usersList,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!,
    ticketTypesMetadata: state.ticketTypesMetadata,
    tabs: state.ticketTabs,
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTicket: (id: string, data: Partial<Ticket>) => {
      dispatch(updateTicket(id, data));
    },
    closeAsDone: async (id: string, UID: string | undefined) => {
      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      await dispatch(updateTicket(id, { status: 'done' }, true));

      SocketInstance.leaveRoom(id);
    },
    removeTicketFromTicketlist: ({ ticketId }: { ticketId: string }) => {
      dispatch(removeTicketFromTicketlist({ ticketId, id: StaticTabs.MAIN_VIEW }));
    },
    closeAndUpdateTicketType: async ({
      UID,
      ticketId: id,
      taskType,
      activeId
    }: {
      UID: string;
      ticketId: string;
      taskType: string;
      activeId: string;
    }) => {
      await dispatch(updateTicket(id, { taskType }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);

      if (activeId !== StaticTabs.MAIN_VIEW) {
        dispatch(activateTab(activeId));
      }
    },
    returnAsNew: async (id: string, UID: string | undefined) => {
      await dispatch(updateTicket(id, { status: 'todo' }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    },
    closeAndReturnAsDoing: async (id: string, UID: string | undefined) => {
      await dispatch(updateTicket(id, { status: 'doing' }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    },
    stopWorkingOn: async (id: string, UID?: string) => {
      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface TopBarStatusButtonsHOCProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

export default withRouter(connector(TopBarStatusButtons));

import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { AxiosError } from 'axios';
import type { IziToastSettings } from 'izitoast';
import type { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import ArchivalApi from '../api/ArchivalApi';
import { history } from '../reducers';
import { taskIdToNumericalId } from '../Utilities/ticketList';
import { parseComments } from '../Utilities/userComment';
import { startAjaxCall } from './ajaxStatusActions';
import { fetchEntityTags } from './entityTagsActions';
import {
  ACTIVATE_TICKET,
  ADD_TICKET,
  ADDED_ATTACHMENT_TO_TICKET,
  CLEAR_DETAILED_TICKETS,
  CREATE_INFO_PAGE,
  CREATE_INFO_PAGE_SUCCESS,
  CREATE_TICKET,
  CREATE_TICKET_SUCCESS,
  DEACTIVATE_TICKET,
  FALSIFY_INSTANT_SCROLL,
  FALSIFY_SCROLL,
  FETCH_CHANNEL_TYPES_FAILURE,
  FETCH_CHANNEL_TYPES_SUCCESS,
  FETCH_LINKED_TICKETS_ERROR,
  FETCH_LINKED_TICKETS_SUCCESS,
  FETCH_RESPONSE_TEMPLATES_FAILURE,
  FETCH_TICKET_PRIORITIES_ERROR,
  FETCH_TICKET_PRIORITIES_SUCCESS,
  FETCH_TICKET_TYPES_FAILURE,
  FETCH_TICKET_TYPES_METADATA_SUCCESS,
  FETCH_TITLE_TEMPLATES_FAILURE,
  GET_ENTITY_BY_ID_FAILURE,
  GET_ENTITY_BY_ID_SUCCESS,
  GET_SUB_ENTITY_BY_ID_FAILURE,
  GET_SUB_ENTITY_BY_ID_SUCCESS,
  RESET_LINKED_TICKETS,
  SAVE_ENTITY_CHANGE,
  SCROLL_CASE_BOTTOM_INSTANTLY,
  SEARCH_ENTITY_BY_DETAILS_SUCCESS,
  UPDATE_METADATA_FAILURE,
  UPDATE_METADATA_SUCCESS,
  UPDATE_TICKET_FAILURE,
  UPDATE_TICKET_SUCCESS
} from './index';
import { fetchInfoPagesSuccess } from './infoPagesActionsRTK';
import { initialRequestFailure, initialRequestSuccess } from './initActions';
import { activateTab, addTab, closeTab } from './tabActionsRTK';
import {
  fetchTicketsError,
  fetchTicketsSuccess,
  fetchTicketSuccess,
  parseTicketCommentsSuccess,
  removeTicketFromDetailed
} from './ticketsActionsRTK';
import { addComment } from 'src/actions/commentsActions';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';
import EntityApi from 'src/api/EntityApi';
import EntityEventsApi from 'src/api/EntityEventsApi';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import { contentsVC } from 'src/api/VersionControl';
import SocketInstance from 'src/realTimeNotifications';
import { loadResponseTemplatesSuccess, loadTitleTemplatesSuccess } from 'src/reducers/templatesReducer';
import { fetchTicketTypesSuccess } from 'src/reducers/typesReducer';
import { typeToPrefix } from 'src/types/ContentTypes';
import { StaticTabs } from 'src/types/TicketList';
import { getPrefixByType } from 'src/Utilities/helper';
import { getTicketHash } from 'src/Utilities/ticket';
import { startWorkingOn, stopWorkingOn } from 'src/Utilities/workStatusParser';

import type { TicketArchivalUploadData } from '../types/Archival';
import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { AttachmentEdit } from 'src/Components/Attachments/AttachmentItem';
import type { Channel } from 'src/types/Channel';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { EntityEventWithMultipleEntities } from 'src/types/EntityEvents';
import type { Field } from 'src/types/Info';
import type { State } from 'src/types/initialState';
import type { LinkedTicket } from 'src/types/LinkedTickets';
import type { FormattedSearch } from 'src/types/Search';
import type { ThunkAppDispatch } from 'src/types/store';
import type { Suggestion } from 'src/types/Suggestions';
import type { ContentTypesFields, Entity, EntityResponse, SubEntityResponse, Ticket } from 'src/types/Ticket';
import type { CaseType } from 'src/types/TicketList';
import type { FieldSet, TicketType } from 'src/types/TicketType';
import type { InfoPageListItem } from 'src/Utilities/infoPageList';

interface SubEntityFetchParams {
  ticketId: string;
  taskType: string;
  entityId: string | number;
  entityType: string;
  parentEntityType: string;
  parentEntityId: string;
}

export const clearDetailedTickets = () => {
  return { type: CLEAR_DETAILED_TICKETS };
};

export const loadLinkedTicketsSuccess = (tickets: Array<Ticket>, id: string) => {
  return { type: FETCH_LINKED_TICKETS_SUCCESS, payload: { id, tickets } };
};

export const loadLinkedTicketsError = (id: string) => {
  return { type: FETCH_LINKED_TICKETS_ERROR, payload: { id } };
};

export const resetLinkedTickets = () => {
  return { type: RESET_LINKED_TICKETS };
};

export const loadTicketTypesMetadataSuccess = (ticketTypesMetadata: any[]) => {
  return {
    type: FETCH_TICKET_TYPES_METADATA_SUCCESS,
    payload: { ticketTypesMetadata }
  };
};

export const getTicketEntityByIdSuccess = (response: EntityResponse) => {
  return { type: GET_ENTITY_BY_ID_SUCCESS, payload: { ...response } };
};

export const getTicketEntityByIdFailure = (error: Error) => {
  return { type: GET_ENTITY_BY_ID_FAILURE, payload: { error } };
};

export const getSubEntityByIdSuccess = (response: SubEntityResponse) => {
  return { type: GET_SUB_ENTITY_BY_ID_SUCCESS, payload: { ...response } };
};

export const getSubEntityByIdFailure = (error: Error) => {
  return { type: GET_SUB_ENTITY_BY_ID_FAILURE, payload: { error } };
};

export const loadTicketTypesFailure = (error: Error) => {
  return {
    type: FETCH_TICKET_TYPES_FAILURE,
    payload: { type: 'ticketTypes', error: error }
  };
};

export const loadResponseTemplatesFailure = (error: Error) => {
  return {
    type: FETCH_RESPONSE_TEMPLATES_FAILURE,
    payload: { type: 'titleTemplates', error: error }
  };
};

export const loadTitleTemplatesFailure = (error: Error) => {
  return {
    type: FETCH_TITLE_TEMPLATES_FAILURE,
    payload: { type: 'responseTemplates', error: error }
  };
};

export const loadChannelTypesSuccess = (channelTypes: Channel[]) => {
  return { type: FETCH_CHANNEL_TYPES_SUCCESS, payload: { channelTypes } };
};

export const loadTicketPrioritiesSuccess = (priorities: Suggestion[]) => {
  return { type: FETCH_TICKET_PRIORITIES_SUCCESS, payload: { priorities } };
};

export const loadTicketPrioritiesError = () => {
  return { type: FETCH_TICKET_PRIORITIES_ERROR };
};

export const loadChannelTypesFailure = (error: Error) => {
  return {
    type: FETCH_CHANNEL_TYPES_FAILURE,
    payload: { type: 'channelTypes', error: error }
  };
};

export const searchEntityByDetailsSuccess = (entities: Entity[]) => {
  return { type: SEARCH_ENTITY_BY_DETAILS_SUCCESS, entities };
};

export const createTicketSuccess = (ticket: Ticket) => {
  return { type: CREATE_TICKET_SUCCESS, ticket };
};

export const updateMetadataSuccess = (id: number, metaData: { [key: string]: any }) => {
  return { type: UPDATE_METADATA_SUCCESS, payload: { id, metaData } };
};

export const updateMetadataFailure = (id: number, metaData: { [key: string]: any }) => {
  return { type: UPDATE_METADATA_FAILURE, payload: { id, metaData } };
};

export const updateTicketSuccess = (ticketId: string, payload: any) => {
  return { type: UPDATE_TICKET_SUCCESS, ticketId, payload };
};

export const updateTicketFailure = (ticketId: string, payload: any) => {
  return { type: UPDATE_TICKET_FAILURE, ticketId, payload };
};

export const addedAttachmentToTicket = (ticketId: string, payload: any) => {
  return { type: ADDED_ATTACHMENT_TO_TICKET, ticketId, payload };
};

export const addTicket = (ticket: Ticket) => {
  return { type: ADD_TICKET, payload: { ticket } };
};

export const saveEntityChange = (data: any, id: any, entityDisplayName: string) => {
  return { type: SAVE_ENTITY_CHANGE, payload: { data, id, entityDisplayName } };
};

export const scrollCaseBottomInstantly = () => {
  return { type: SCROLL_CASE_BOTTOM_INSTANTLY };
};

interface TicketAction extends AnyAction {
  type: string;
}

export const activateTicket: ActionCreator<TicketAction> = (id: string) => {
  return { type: ACTIVATE_TICKET, payload: id };
};

export const falsifyScroll: ActionCreator<TicketAction> = () => {
  return { type: FALSIFY_SCROLL };
};

export const falsifyInstantScroll: ActionCreator<TicketAction> = () => {
  return { type: FALSIFY_INSTANT_SCROLL };
};

export const deActivateTicket: ActionCreator<TicketAction> = (id: string) => {
  return { type: DEACTIVATE_TICKET, payload: id };
};

export const fetchTicketTypes: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TICKET_TYPES' }));
    return TicketsApi.getTicketTypes()
      .then((ticketTypes: any) => {
        dispatch(fetchTicketTypesSuccess(ticketTypes.allowedTicketTypes));
        dispatch(loadTicketTypesMetadataSuccess(ticketTypes.ticketTypesMetadata));
        dispatch(initialRequestSuccess('ticketTypes'));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load ticket types', error);
        dispatch(loadTicketTypesFailure(error));
        dispatch(initialRequestFailure('ticketTypes', error));
      });
  };
};

// TODO: taskType not needed here anymore?
export const getTicketEntityById: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  taskType: string,
  entityId: string,
  entityType: string
) => {
  const params = { ticketId, taskType, entityId, entityType };
  return (dispatch, getState) => {
    dispatch(startAjaxCall({ name: 'GET_ENTITY', payload: { params } }));
    return EntityApi.getTicketEntityById(params)
      .then((response: EntityResponse) => {
        dispatch(getTicketEntityByIdSuccess({ ...response, ticketId }));

        /// checking for sub-entities on the fetched entity
        const { ticketTypes } = getState();
        let subEntityFields = [] as Field[];
        const ticketTypeOfTheEntity = ticketTypes.find((tType: TicketType) => tType.name === taskType);

        ticketTypeOfTheEntity?.fieldSets.forEach((fieldSet: FieldSet) => {
          if (
            fieldSet.id === 'customerInfo' &&
            fieldSet.entityTypes?.find((fEntityType: string) => fEntityType === entityType) !== undefined
          ) {
            const subEntityFieldsOfFieldSet = fieldSet.customerInfo?.filter(
              (field: Field) => field.customType === 'subEntity'
            );
            if (subEntityFieldsOfFieldSet !== undefined && subEntityFieldsOfFieldSet.length > 0) {
              subEntityFields = subEntityFields.concat(subEntityFieldsOfFieldSet);
            }
          } else if (fieldSet.id === 'customerInfo') {
            /// in cases of the entity types are not defined yet there is a sub-entity in use
            /// drops the requirent on defining the entity type in single entity fieldset ticket types
            const subEntityFieldsOfFieldSet = fieldSet.customerInfo?.filter(
              (field: Field) => field.customType === 'subEntity'
            );
            if (subEntityFieldsOfFieldSet !== undefined && subEntityFieldsOfFieldSet.length > 0) {
              subEntityFields = subEntityFields.concat(subEntityFieldsOfFieldSet);
            }
          }
        });

        if (subEntityFields.length > 0) {
          const subEntitiesToBeFetched = [] as SubEntityFetchParams[];
          subEntityFields.forEach((subEntityField) => {
            let subEntityId = undefined;
            if (subEntityField.params?.getByValue && response.data !== undefined) {
              subEntityId = response.data[subEntityField.params.getByValue];
            } else {
              subEntityId = entityId;
            }
            if ((typeof subEntityId === 'string' && subEntityId !== '') || typeof subEntityId === 'number') {
              subEntitiesToBeFetched.push({
                ticketId: ticketId,
                taskType: taskType,
                entityId: subEntityId,
                entityType: subEntityField.params?.entityType ?? '',
                parentEntityType: entityType,
                parentEntityId: response._id
              });
            }
          });
          subEntitiesToBeFetched.forEach((subEntityFetch) => {
            dispatch(fetchSubEntity(subEntityFetch));
          });
        }
      })
      .catch((error: Error) => {
        dispatch(getTicketEntityByIdFailure(error));
      });
  };
};

// TODO: wrap in thunk
export const fetchSubEntity: ActionCreator<any> = (params: any) => {
  // using this as any as the sub-entity system allows for specifying non-string id:s as searched entity ids
  const { ticketId, taskType, entityId, entityType, parentEntityType, parentEntityId } = params;
  return (dispatch: Dispatch) => {
    const entityGetParams = { ticketId, taskType, entityId, entityType };

    return EntityApi.getTicketEntityById(entityGetParams)
      .then((response: EntityResponse) => {
        dispatch(getSubEntityByIdSuccess({ ...response, ticketId, parentEntityType, parentEntityId }));
      })
      .catch((error: Error) => {
        dispatch(getSubEntityByIdFailure(error));
      });
  };
};

export const fetchResponseTemplates: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_RESPONSE_TEMPLATES' }));
    return TicketsApi.getResponseTemplates()
      .then((templates) => {
        dispatch(loadResponseTemplatesSuccess(templates));
        dispatch(initialRequestSuccess('responseTemplates'));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load response templates', error);
        dispatch(loadResponseTemplatesFailure(error));
        dispatch(initialRequestFailure('responseTemplates', error));
      });
  };
};

export const fetchTitleTemplates: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_RESPONSE_TEMPLATES' }));
    return TicketsApi.getTitleTemplates()
      .then((templates) => {
        dispatch(loadTitleTemplatesSuccess(templates));
        dispatch(initialRequestSuccess('titleTemplates'));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load response templates', error);
        dispatch(loadTitleTemplatesFailure(error));
        dispatch(initialRequestFailure('responseTemplates', error));
      });
  };
};

export const fetchChannelTypes: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_CHANNEL_TYPES' }));
    return TicketsApi.getChannelTypes()
      .then((channelTypes) => {
        dispatch(loadChannelTypesSuccess(channelTypes));
        dispatch(initialRequestSuccess('channelTypes'));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load channel types', error);
        dispatch(loadChannelTypesFailure(error));
        dispatch(initialRequestFailure('channelTypes', error));
      });
  };
};

export const updateGiosgWritingStatus: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  chat_id: string,
  agentName: string,
  room_id: string
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_GIOSG_WRITING_STATUS' }));
    return TicketsApi.updateGiosgWritingStatus(chat_id, agentName, room_id).catch((error: AxiosError) => {
      console.error('Failed to update giosg write status', error);
    });
  };
};

export const fetchTickets: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  filter: FormattedSearch,
  id: string,
  throughSearchTab: boolean
) => {
  const showIziToast = (settings: IziToastSettings) => {
    setTimeout(() => {
      iziToast.show({
        displayMode: 2,
        id: 'searchToast',
        target: '.toastSearchTarget',
        ...settings
      });
    }, 500);
  };

  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TICKETS' }));
    return TicketsApi.getTickets({ searchterms: { ...filter } })
      .then((tickets) => {
        if ((!tickets || tickets.length < 1) && throughSearchTab) {
          showIziToast({
            color: 'blue',
            message: t('SEARCH_RESULT_NO_TICKETS')
          });
        } else if (tickets && tickets.length === 1 && tickets[0].id === 'Error') {
          showIziToast({
            color: 'red',
            message: t('SEARCH_FAILED')
          });
        } else if (tickets && tickets.length >= 1 && throughSearchTab && tickets[0].id !== 'Error') {
          showIziToast({
            color: 'green',
            message: t('SEARCH_RESULTS_TICKETS_COUNT') + '' + tickets.length + '' + t('SEARCH_TICKETS_PREFIX')
          });
        }

        dispatch(fetchTicketsSuccess({ tickets, id }));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load tickets', error);
        dispatch(fetchTicketsError(id));
      });
  };
};

export const fetchTicketsByEntity: ActionCreator<ThunkAction<any, any, any, Action<any>>> = (
  entityId: string,
  tabId: string,
  entityType: string,
  caseType: CaseType = 'task'
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TICKETS_BY_CUSTOMER' }));
    return TicketsApi.getTicketsByEntity(entityId, entityType, caseType)
      .then((tickets) => {
        if (caseType === 'task') {
          dispatch(fetchTicketsSuccess({ tickets, id: tabId }));
        }
        if (caseType === 'infopage') {
          dispatch(fetchInfoPagesSuccess({ tickets: tickets as InfoPageListItem[], id: tabId }));
        }
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const searchCustomerByDetail: ActionCreator<ThunkAction<Promise<Entity[]>, any, any, Action<any>>> = (
  params
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'SEARCH_CUSTOMER_BY_DETAILS' }));
    return TicketsApi.searchEntityByDetails(params)
      .then((entities: Entity[]) => {
        dispatch(searchEntityByDetailsSuccess(entities));
        return entities;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const fetchTicket: ActionCreator<ThunkAction<any, State, any, any>> = ({
  id,
  closeTicketAfterFail = true,
  shouldActivateTicket = true,
  forceFetch,
  param,
  type
}: {
  id: string;
  closeTicketAfterFail?: boolean;
  shouldActivateTicket?: boolean | (() => boolean);
  forceFetch?: boolean;
  param?: any;
  type?: ContentTypes;
}) => {
  return (dispatch: ThunkAppDispatch, getState: () => State) => {
    const { detailedTickets, ticketListTabs, linkedTickets } = getState();
    const detailedTicket = detailedTickets.find((ticket) => ticket.id === id);
    if (!forceFetch && detailedTicket) {
      if (detailedTicket.touched === ticketListTabs['MAIN_VIEW'].tickets.find((ticket) => ticket.id === id)?.touched) {
        dispatch(activateTicket(id));
        return;
      }
    }

    let relatedTicketNeedsTobeUpdated = false;

    Object.keys(linkedTickets.tickets).forEach((relatedTicketSubGroupName) => {
      const relatedTicketSubGroupCopy: LinkedTicket[] = linkedTickets.tickets[relatedTicketSubGroupName];
      const foundTicket = relatedTicketSubGroupCopy.find((relatedTicket) => id === relatedTicket.id);

      if (foundTicket !== undefined) {
        relatedTicketNeedsTobeUpdated = true;
      }
    });

    if (param === 'updatedRelatedTicketsList') {
      relatedTicketNeedsTobeUpdated = true;
    }

    dispatch(startAjaxCall({ name: 'FETCH_TICKET', id }));

    return TicketsApi.getTicket(id, { type: getPrefixByType(type) })
      .then((ticket) => {
        dispatch(fetchTicketSuccess({ ticket }));
        if (
          (typeof shouldActivateTicket === 'boolean' && shouldActivateTicket) ||
          (typeof shouldActivateTicket === 'function' && shouldActivateTicket())
        ) {
          dispatch(activateTicket(id));
        }

        dispatch(parseTicketComments(id));

        if (relatedTicketNeedsTobeUpdated) {
          dispatch(fetchLinkedTickets({ id }));
        }

        ticket.entities.forEach((entity) => {
          const detailedEntity = detailedTicket?.entities.find((e) => entity._id === e._id && entity._type === e._type);

          if (!detailedEntity?.data && !detailedEntity?.errorMessage) {
            dispatch(getTicketEntityById(ticket.id, ticket.taskType, entity._id, entity._type));
            if (FeatureFlags.isFlagOn('ENABLE_ENTITY_TAGS')) {
              dispatch(fetchEntityTags({ entityId: entity._id }));
            }
          } else if (detailedTicket?.id) {
            dispatch(
              getTicketEntityByIdSuccess({ ...detailedEntity, ticketId: detailedTicket.id, isLoading: false } as any)
            );
          }
        });
      })
      .catch((error) => {
        console.error('Error while fetching ticket', error);

        if (closeTicketAfterFail) {
          dispatch(closeTicketTabAndNavigate({ contentId: id }));
        }

        if (error.message === 'Request failed with status code 403') {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: t('ACCESS_DENIED_FOR_TICKET'),
            timeout: 7500
          });
        } else {
          iziToast.error({
            title: t('FETCH_FAILED'),
            icon: 'icon delete',
            message: t('SPECIFIC_TICKET_FETCH_FAILED', { id }),
            timeout: 5000
          });
        }
      });
  };
};

export const closeTicketTabAndNavigate = createAsyncThunk<void, { contentId: string }, { rejectValue: Error }>(
  'closeTicket',
  async ({ contentId }, { dispatch, getState }) => {
    dispatch(closeTab(contentId));
    dispatch(removeTicketFromDetailed(contentId));

    const { ticketTabs } = getState() as State;
    const tabs = ticketTabs.filter((tab) => {
      return tab.id !== contentId && tab.id.substring(0, 3) === typeToPrefix('task');
    });

    if (tabs.length === 0) {
      history.push('/');
    } else if (tabs.length > 0) {
      const activeId = tabs[tabs.length - 1].id;
      dispatch(activateTab(activeId));
    }
  }
);

export const parseTicketComments: ActionCreator<ThunkAction<any, State, any, any>> = (id: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const {
      usersList: { usersList },
      detailedTickets
    } = getState();

    const detailedTicket = detailedTickets.find((ticket) => ticket.id === id);
    if (!detailedTicket) {
      return;
    }

    // This parsing can take some time, so I use setTimeout to execute it after render whole page
    setTimeout(() => {
      const { comments } = detailedTicket;
      const parsedComments = parseComments(comments, usersList, detailedTicket?.attachments ?? []);

      dispatch(parseTicketCommentsSuccess({ id, comments: parsedComments }));
    }, 0);
  };
};

export const updateMetadata: ActionCreator<ThunkAction<any, State, any, any>> = (
  id: number,
  metadata: { [key: string]: any }
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_METADATA', id }));

    return TicketsApi.updateMetadata(id, metadata)
      .then((data: { metaData: object }) => {
        dispatch(updateMetadataSuccess(id, data.metaData));
        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: t('METADATA_SAVE_SUCCESS'),
          timeout: 2500
        });
      })
      .catch((error: AxiosError) => {
        console.error('Error while updating metadata', error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: t('METADATA_SAVE_FAILURE'),
          timeout: 7500
        });
        dispatch(updateMetadataFailure(id, error));
      });
  };
};

export const updateTicket: ActionCreator<ThunkAction<any, State, any, any>> = (
  id: string,
  ticket: Partial<Ticket>,
  closeAfterUpdate: boolean
) => {
  return async (dispatch: ThunkAppDispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_TICKET', id: id }));

    try {
      const response = await TicketsApi.updateTicket(id, ticket);
      contentsVC.clearEntry(taskIdToNumericalId(id));

      if (closeAfterUpdate) {
        dispatch(closeTicketTabAndNavigate({ contentId: id }));
      }

      dispatch(fetchTicketSuccess({ ticket: response }));
      dispatch(updateTicketSuccess(id, {}));

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: t('TICKET_SAVE_SUCCESS'),
        timeout: 2500
      });
    } catch (error) {
      console.error('Error while updating ticket', error);
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: t('TICKET_SAVE_FAILURE'),
        timeout: 7500
      });
      dispatch(updateTicketFailure(id, {}));
    }
  };
};

export const updateTicketNoLocalUpdate: ActionCreator<ThunkAction<any, State, any, any>> = (
  id: string,
  ticket: Partial<Ticket>
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_TICKET', id: id }));
    return TicketsApi.updateTicket(id, ticket)
      .then(() => {
        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: t('TICKET_SAVE_SUCCESS'),
          timeout: 2500
        });
      })
      .catch((error) => {
        console.error('Error while updating ticket', error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: t('TICKET_SAVE_FAILURE'),
          timeout: 7500
        });
        dispatch(updateTicketFailure(id, {}));
      });
  };
};

export const updateSingleTicketCaseDetail: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  updateKey: string,
  updateValue: any,
  group: string
) => {
  return async (dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_SINGLE_TICKET_DETAIL', id: ticketId }));
    try {
      await TicketsApi.updateSingleTicketDetail(ticketId, updateKey, updateValue, group);
      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: `'${updateValue}' ${t('TICKET_DETAIL_SAVE_SUCCESS')}`,
        timeout: 2500
      });
      return dispatch(updateTicketSuccess(ticketId, { updateKey, updateValue, group }));
    } catch (error) {
      console.error("Error while updating ticket's case details", error);
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: `'${updateValue}' ${t('TICKET_DETAIL_SAVE_FAILURE')}`,
        timeout: 7500
      });
      return dispatch(updateTicketFailure(ticketId, {}));
    }
  };
};

export const updateTicketCaseDetails: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  group: string,
  items: { type: string; value: any }[]
) => {
  return async (dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_TICKET_DETAILS', id: ticketId }));
    try {
      await TicketsApi.updateTicketDetails(ticketId, group, items);
      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: `'${ticketId}' ${t('TICKET_DETAIL_SAVE_SUCCESS')}`,
        timeout: 2500
      });
      return items.map((item) =>
        dispatch(updateTicketSuccess(ticketId, { updateKey: item.type, updateValue: item.value, group }))
      );
    } catch (error) {
      console.error("Error while updating ticket's case details", error);
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: `'${ticketId}' ${t('TICKET_DETAIL_SAVE_FAILURE')}`,
        timeout: 7500
      });
      return dispatch(updateTicketFailure(ticketId, {}));
    }
  };
};

// TODO: wrap in thunk
export function createContent(ticket: object, manuallyCreated = false, type: ContentTypesFields = 'tickets') {
  return (dispatch: Dispatch): Promise<Ticket> => {
    let ajaxName: string;
    let startWorking: boolean;

    switch (type) {
      case 'tickets':
      default:
        ajaxName = CREATE_TICKET;
        startWorking = manuallyCreated;
        break;
      case 'infopages':
        ajaxName = CREATE_INFO_PAGE;
        startWorking = false;
        break;
    }

    dispatch(startAjaxCall({ name: ajaxName }));

    // TODO info pages cleanup
    return TicketsApi.makeTicket(ticket)
      .then(async (response) => {
        dispatch(activateTicket(response.id));
        dispatch(createTicketSuccess(response));
        dispatch(addTab({ id: response.id, type }));
        dispatch(activateTab(response.id));
        if (startWorking) {
          await TicketsApi.startWorkingOn(...startWorkingOn(response.createdByUser, response.id));
        }
        if (type === 'infopages') {
          dispatch({ type: CREATE_INFO_PAGE_SUCCESS });
        }

        return response;
      })
      .catch((error) => {
        console.error('Failed to create content', error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: ` ${t('TICKET_CREATION_FAILURE')}`,
          timeout: 7500
        });
        return ticket as Ticket;
      });
  };
}

// TODO: wrap in thunk
export const createNewSubTicket = (
  type: ContentTypes,
  ticket: object,
  manuallyCreated = false,
  parentTickedId: string,
  ticketRelationType: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'CREATE_TICKET' }));

    let prefix: string;
    switch (type) {
      case 'task':
      default:
        prefix = 'TSK';
        break;
      case 'infopage':
        prefix = 'INF';
        break;
    }

    return TicketsApi.makeTicket(ticket)
      .then((response) => {
        dispatch(createTicketSuccess(response));
        dispatch(activateTicket(response.id));
        dispatch(addTab({ id: response.id, type: 'tickets' }));
        dispatch(activateTab(response.id));
        dispatch(
          addRelatedTicketToTicket(prefix + parentTickedId, response.id.substring(3), ticketRelationType) as any
        );

        if (manuallyCreated) {
          TicketsApi.startWorkingOn(...startWorkingOn(response.createdByUser, response.id));
        }
        return response;
      })
      .catch((error) => {
        console.error('Failed to create ticket', error);
        throw error;
      });
  };
};

export const updateEntityDetails =
  ({
    ticketId,
    taskType,
    updateArgs
  }: {
    ticketId?: string;
    taskType?: string;
    updateArgs: UpdateEntityDetail;
  }): ThunkAction<Promise<void>, State, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(
      startAjaxCall({
        name: 'UPDATE_CUSTOMER_DETAILS',
        id: updateArgs.entityId
      })
    );

    return TicketsApi.updateEntityDetail({ taskType, updateArgs })
      .then((response) => {
        if (response.nModified > 0) {
          // we have modified something
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: `'${updateArgs.valueToSave}' ${t('TICKET_DETAIL_SAVE_SUCCESS')}`,
            timeout: 2500
          });
        } else if (response.nModified === 0) {
          // do nothing since nothing was modified actually
          // maybe in future do check in input and here give error
          // since then having nothing modified would be error
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `'${updateArgs.valueToSave}' ${t('TICKET_DETAIL_SAVE_FAILURE')}`,
            timeout: 7500
          });
        }
        const group = 'EntityDetails';
        if (ticketId !== undefined) {
          dispatch(updateTicketSuccess(ticketId, { ...updateArgs, group }));
        }
      })
      .catch((error) => {
        console.error("Error while updating entity's details", error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `'${updateArgs.valueToSave}' ${t('TICKET_DETAIL_SAVE_FAILURE')}`,
          timeout: 7500
        });
        if (ticketId !== undefined) {
          dispatch(updateTicketFailure(ticketId, {}));
        }
      });
  };

const checkSubjectForTSKDuplicates = (value: any, ticket: Partial<Ticket>) => {
  const id = ticket.id;
  const body = value;
  const hash = getTicketHash(ticket.id!, ticket.created!);
  const isTSKHashAlreadyUsed = value.subject.includes(`[${id}${hash}]`);
  if (value.subject !== '' && !isTSKHashAlreadyUsed) {
    body.subject = `${value.subject} [${id}${hash}]`;
  }
  return body;
};

export const onCommentSubmit = (ticket: Partial<Ticket>, value: any) => {
  const checkedBody = value.subject ? checkSubjectForTSKDuplicates(value, ticket) : value;

  return addComment(ticket.id, checkedBody);
};

export const updateChatTypingStatusToCustomer: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  connectionId: string
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_CHAT_WRITING_STATUS' }));
    return TicketsApi.updateChatTypingStatusToCustomer(connectionId).catch((error: AxiosError) => {
      console.error('Failed to update chat type status', error);
    });
  };
};

export const removeEntityFromCase: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  body: {
    _id: string;
    _type: string;
  }
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'REMOVE_CUSTOMER', id: ticketId }));
    return TicketsApi.removeEntityFromCase(ticketId, body)
      .then((response) => {
        if (typeof response.entityId !== 'undefined' && typeof response.caseId !== 'undefined') {
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: t('TICKET_CUSTOMER_DETACH_SUCCESS'),
            timeout: 2500
          });
          dispatch(
            updateTicketSuccess(ticketId, {
              group: 'Entities',
              type: 'REMOVE',
              entityId: body._id,
              entityType: body._type
            })
          );
        } else {
          console.error('Error while removing entity from case', response);
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: t('TICKET_CUSTOMER_DETACH_FAILURE'),
            timeout: 7500
          });
          dispatch(updateTicketFailure(ticketId, {}));
        }
      })
      .catch((error) => {
        console.error('Error while removing entity from case', error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: t('TICKET_CUSTOMER_DETACH_FAILURE'),
          timeout: 7500
        });
        dispatch(updateTicketFailure(ticketId, {}));
      });
  };
};

export const addEntityToCase: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  body: {
    taskType: string;
    _id: string;
    _type: string;
  }
) => {
  return (dispatch, getState) => {
    dispatch(startAjaxCall({ name: 'ADD_CUSTOMER', id: ticketId }));
    return TicketsApi.addEntityToCase(ticketId, body)
      .then((response) => {
        const { ticketTypes } = getState();
        const ticketTypeOfTheEntity = ticketTypes.find((tType: TicketType) => tType.name === body.taskType);

        const customerFieldSets = ticketTypeOfTheEntity?.fieldSets.filter(
          (fieldSet: FieldSet) => fieldSet.id === 'customerInfo'
        );
        const fieldSetOfTheEntity =
          customerFieldSets?.length === 1
            ? customerFieldSets[0]
            : customerFieldSets?.find(
                (CfieldSet: FieldSet) =>
                  !!CfieldSet?.entityTypes?.find((fEntityType: string) => fEntityType === response._type)
              );

        const subEntitiesToBeFetched = fieldSetOfTheEntity?.customerInfo?.filter(
          (field: Field) => field.customType === 'subEntity'
        );

        if (typeof response._id !== 'undefined') {
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: t('TICKET_CUSTOMER_ATTACH_SUCCESS'),
            timeout: 2500
          });
          dispatch(
            updateTicketSuccess(ticketId, {
              type: 'ADD',
              data: response,
              group: 'Entities'
            })
          );

          if (subEntitiesToBeFetched?.length && subEntitiesToBeFetched.length > 0) {
            dispatch(
              fetchSubEntity({
                ticketId: ticketId,
                taskType: body.taskType,
                entityId: response._id,
                entityType: subEntitiesToBeFetched[0].params?.entityType,
                parentEntityType: response._type
              })
            );
          }
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: t('TICKET_CUSTOMER_ATTACH_FAILURE'),
            timeout: 7500
          });
          dispatch(updateTicketFailure(ticketId, {}));
        }
      })
      .catch((error) => {
        console.error('Error from adding entity to case', error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: t('TICKET_CUSTOMER_ATTACH_FAILURE'),
          timeout: 7500
        });
        dispatch(updateTicketFailure(ticketId, {}));
      });
  };
};

export const sendTicketArchivalRequest: ActionCreator<ThunkAction<any, State, any, any>> = (
  sendData: TicketArchivalUploadData
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'TICKET_ARCHIVAL_REQUEST_SENT' }));

    return ArchivalApi.sendArchivalRequest(sendData).then((response) => {
      if (response.status === 200) {
        return;
      } else {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `${t('TICKET_ARCHIVAL_REQUEST_FAILURE')}.`,
          timeout: 7500
        });
        return;
      }
    });
  };
};

export const addTagToContent: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  tagId: string,
  addnew: boolean
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'ADD_TAG', id: ticketId }));

    return TicketsApi.addTagToContent(ticketId, tagId, addnew)
      .then((response) => {
        if (typeof response.id !== 'undefined') {
          dispatch(
            updateTicketSuccess(ticketId, {
              group: 'Tag',
              remove: false,
              id: response.id
            })
          );
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: `${t('TICKET_TAG_SAVE_SUCCESS')}.`,
            timeout: 2500
          });
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t('TICKET_TAG_SAVE_FAILURE')}.`,
            timeout: 7500
          });
          dispatch(updateTicketFailure(ticketId, {}));
        }
      })
      .catch((error: AxiosError) => {
        console.error('Error while adding tag to ticket', error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `${t('TICKET_TAG_SAVE_FAILURE')}.`,
          timeout: 7500
        });
        dispatch(updateTicketFailure(ticketId, {}));
      });
  };
};

export const removeTagFromContent: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  tagId: string
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'REMOVE_TAG', id: ticketId }));

    return TicketsApi.removeTagFromContent(ticketId, tagId)
      .then(() => {
        dispatch(
          updateTicketSuccess(ticketId, {
            group: 'Tag',
            remove: true,
            id: tagId
          })
        );
        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: `${t('TICKET_TAG_REMOVE_SUCCESS')}.`,
          timeout: 2500
        });
      })
      .catch((error: AxiosError) => {
        console.error('Error while removing tag from content', error);
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `${t('TICKET_TAG_REMOVE_FAILURE')}.`,
          timeout: 7500
        });
        dispatch(updateTicketFailure(ticketId, {}));
      });
  };
};

export const addDelegateToContent: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  usr: string
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'ADD_DELEGATE', id: ticketId }));

    return TicketsApi.addDelegateToContent(ticketId, usr)
      .then(() => {
        dispatch(
          updateTicketSuccess(ticketId, {
            group: 'Delegate',
            remove: false,
            usr: usr
          })
        );

        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: `${t('TICKET_DELEGATION_ADD_SUCCESS')}.`,
          timeout: 2500
        });
      })
      .catch(() => {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `${t('TICKET_DELEGATION_ADD_FAILURE')}.`,
          timeout: 7500
        });
        dispatch(updateTicketFailure(ticketId, {}));
      });
  };
};

export const removeDelegateFromContent: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  usr: string
) => {
  return async (dispatch) => {
    dispatch(startAjaxCall({ name: 'REMOVE_DELEGATE', id: ticketId }));

    try {
      await TicketsApi.removeDelegateFromContent(ticketId, usr);
      dispatch(
        updateTicketSuccess(ticketId, {
          group: 'Delegate',
          remove: true,
          usr: usr
        })
      );

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: `${t('TICKET_DELEGATION_REMOVE_SUCCESS')}.`,
        timeout: 2500
      });
    } catch (error) {
      console.error('Error while removing delegate from content');
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: `${t('TICKET_DELEGATION_REMOVE_FAILURE')}.`,
        timeout: 7500
      });
      dispatch(updateTicketFailure(ticketId, {}));
    }
  };
};

export const addDelegateGroupToContent: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  usrGroup: number[]
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'ADD_GROUP_DELEGATE', id: ticketId }));

    return TicketsApi.addGroupDelegateToContent(ticketId, usrGroup)
      .then(() => {
        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: `${t('TICKET_DELEGATION_ADD_SUCCESS')}.`,
          timeout: 2500
        });
      })
      .catch(() => {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `${t('TICKET_DELEGATION_ADD_FAILURE')}.`,
          timeout: 7500
        });
        dispatch(updateTicketFailure(ticketId, {}));
      });
  };
};

export const fileUploaded = createAsyncThunk<
  void,
  { ticketId: string; attachment: { attachmentId: string; secret: string } },
  { rejectValue: Error }
>('fetchLinkedTickets', async ({ ticketId, attachment }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'ATTACH_FILE_TO_CASE', id: ticketId }));

  try {
    const attachResponse = await TicketsApi.attachAttachmentToCase(
      attachment.attachmentId,
      ticketId,
      attachment.secret
    );

    dispatch(addedAttachmentToTicket(ticketId, attachResponse));
    iziToast.success({
      title: t('OK'),
      icon: 'icon check',
      message: `${t('TICKET_ATTACHMENT_ADD_SUCCESS')}.`,
      timeout: 2500
    });
  } catch (error) {
    console.error('Error from uploading file', error);
    iziToast.error({
      title: `${t('ERROR')}!`,
      icon: 'icon delete',
      message: `${t('TICKET_ATTACHMENT_ADD_FAILURE')}.`,
      timeout: 7500
    });
  }
});

export const uploadFile: ActionCreator<ThunkAction<any, State, any, any>> = (ticketId: string, file: FormData) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'UPLOAD_FILE', id: ticketId }));

    return TicketsApi.uploadFile(file).then((response) => {
      const files = response.files;
      if (response.status === 'ok' && Array.isArray(files) && files.length > 0) {
        // here attach attachment to task
        files.forEach((attachment: { attachmentId: string; secret: string }) => {
          dispatch(fileUploaded({ ticketId, attachment }));
        });
        return files;
      } else {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `${t('TICKET_ATTACHMENT_ADD_FAILURE')}.`,
          timeout: 7500
        });
        return;
      }
    });
  };
};

export const sendEntityEvent: ActionCreator<ThunkAction<any, State, any, any>> = (
  eventData: EntityEventWithMultipleEntities
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'ENTITY_EVENT_SENT' }));

    return EntityEventsApi.sendEntityEvent(eventData).then((response) => {
      if (response.status === 200) {
        return;
      } else {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `${t('ENTITY_EVENT_SEND_FAILURE')}.`,
          timeout: 7500
        });
        return;
      }
    });
  };
};

export const fetchLinkedTickets = createAsyncThunk<void, { id: string }, { rejectValue: Error }>(
  'fetchLinkedTickets',
  async ({ id }, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_LINKED_TICKETS' }));

    try {
      const tickets = await TicketsApi.fetchLinkedTickets(id);
      dispatch(loadLinkedTicketsSuccess(tickets, id));
    } catch (error) {
      console.error('Failed to load tickets', error);
      dispatch(loadLinkedTicketsError(id));
    }
  }
);

export const fetchTicketPriorities: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TICKET_PRIORITIES' }));
    return TicketsApi.fetchTicketPriorities()
      .then((priorities) => {
        dispatch(loadTicketPrioritiesSuccess(priorities));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load ticket priorities', error);
        dispatch(loadTicketPrioritiesError());
      });
  };
};

export const addRelatedTicketToTicket: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  id: string,
  targetId: any,
  type: any
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'ADD_CHILD_TICKET_TO_TICKET' }));
    return TicketsApi.addRelatedTicketToTicket(id, targetId, type)
      .then(() => dispatch(fetchLinkedTickets({ id })))
      .catch((error: AxiosError) => {
        console.error('Failed to add child ticket to ticket', error);
      });
  };
};

export const removeTicketLinkingFromTicket: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  id: any,
  targetId: any
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'REMOVE_TICKET_LINKING' }));
    return TicketsApi.removeTicketLinking(id, targetId)
      .then(() => dispatch(fetchLinkedTickets({ id: `TSK${id}` })))
      .catch((error: AxiosError) => {
        console.error('Failed to add child ticket to ticket', error);
      });
  };
};

export const editAttachment: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  attachmentId: string,
  body: AttachmentEdit
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'EDIT_ATTACHMENT', id: ticketId }));

    return TicketsApi.editAttachment(ticketId, attachmentId, body)
      .then((response) => {
        if (typeof response.id !== 'undefined' && Object.keys(response).length > 0) {
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: t('TICKET_ATTACHMENT_EDIT_SUCCESS'),
            timeout: 2500
          });
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t('TICKET_ATTACHMENT_EDIT_FAILURE')}.`,
            timeout: 7500
          });
        }
      })
      .catch((error) => {
        console.error('Error while editing attachment', error);
        throw error;
      });
  };
};

export const deprecateAttachment: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  attachmentId: string
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'DEPRECATE_ATTACHMENT', id: ticketId }));

    return TicketsApi.deprecateAttachment(ticketId, attachmentId)
      .then((response: any) => {
        if (typeof response.id !== 'undefined') {
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: t('TICKET_ATTACHMENT_DEPRECATE_SUCCESS'),
            timeout: 2500
          });
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: t('TICKET_ATTACHMENT_DEPRECATE_FAILURE'),
            timeout: 7500
          });
        }
      })
      .catch((error) => {
        console.error('Error while deprecating attachment', error);
        throw error;
      });
  };
};

export const unDeprecateAttachment: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  attachmentId: string
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'UNDEPRECATE_ATTACHMENT', id: ticketId }));

    return TicketsApi.unDeprecateAttachment(ticketId, attachmentId)
      .then((response: any) => {
        if (typeof response.id !== 'undefined') {
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: t('TICKET_ATTACHMENT_UNDEPRECATE_SUCCESS'),
            timeout: 2500
          });
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: t('TICKET_ATTACHMENT_UNDEPRECATE_FAILURE'),
            timeout: 7500
          });
        }
      })
      .catch((error: AxiosError) => {
        console.error('Error while undeprecating attachment', error);
        throw error;
      });
  };
};

export const changeEntity: ActionCreator<ThunkAction<any, State, any, any>> = (
  data: any,
  id: any,
  entityDisplayName: string
) => {
  return (dispatch) => {
    return dispatch(saveEntityChange(data, id, entityDisplayName));
  };
};

export const automaticClosingTicket: ActionCreator<ThunkAction<void, State, any, any>> = (
  delay: number,
  channel: number[],
  status: string[]
) => {
  const MILLISECONDS = 1000;
  const SECONDS = 60;

  return (dispatch, getState) => {
    const { userData, workStatus, ticketTabs, ticketListTabs } = getState();

    const currentTime = new Date().getTime() / MILLISECONDS;
    const workingStatus = workStatus.status;
    const { UID } = userData;
    const { tickets } = ticketListTabs[StaticTabs.MAIN_VIEW];

    const ticketTabsIds = ticketTabs.filter(({ activeTab: activeTab }) => !activeTab).map(({ id }) => id);
    const workingOnTicketIds = workingStatus
      .filter((status) => UID === 'USR' + status.UID)
      .map(({ ticketId }) => 'TSK' + ticketId);

    const ticketsToClose = tickets.filter(
      (ticket) =>
        ticketTabsIds.includes(ticket.id) &&
        workingOnTicketIds.includes(ticket.id) &&
        status.includes(ticket.status || '') &&
        (!channel.length || channel.includes(ticket.channel)) &&
        currentTime - (ticket.lastEdited || ticket.created) > delay * SECONDS
    );

    if (!ticketsToClose.length) {
      return;
    }

    const closeAsDoneTicket = async (ticketId: string) => {
      await dispatch(updateTicket(ticketId, { status: 'done' }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, ticketId)));
      }

      SocketInstance.leaveRoom(ticketId);
    };

    Promise.all(ticketsToClose.map(({ id }) => closeAsDoneTicket(id)))
      .then(() => {
        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: t('TICKET_CLOSED_AUTOMATICALLY', { count: ticketsToClose.length }),
          timeout: 5000
        });
      })
      .catch((error) => console.error('Failed to automatic close ticket', error));

    return;
  };
};

import { get, isEmpty, map } from 'lodash-es';

import { ChannelType } from 'src/types/Channel';
import { Direction } from 'src/types/Sorting';

import type { Channel } from 'src/types/Channel';
import type { Field } from 'src/types/Info';
import type { Comment, CommentMetadata, Entity, Ticket, TicketDirection } from 'src/types/Ticket';
import type { User } from 'src/types/User';

type OriginalEntity = { _id: string; taskType: string; mongodb: boolean; _type: string; [k: string]: any };

export const getCommentAttachmentsIds = (
  commentMetadata: CommentMetadata | null | undefined,
  task: Ticket
): string[] => {
  const source = !isEmpty(get(commentMetadata, ['originalTicketData', 'data', 'attachments']))
    ? get(commentMetadata, ['originalTicketData', 'data', 'attachments'])
    : get(commentMetadata, ['attachments']);
  const commentAttachments = map(source, (att) => att.filename || att.fileName);

  return task.attachments
    .filter((attachment) => !attachment.deprecated && commentAttachments.includes(attachment.fileName))
    .map((attObj) => attObj.id);
};

/**
 * Determines author [string] representation based on comment channel, comment metaData and entity data
 */
export const getCommentSenderByChannel = (
  channelId: ChannelType,
  metaData: CommentMetadata | null,
  nameFromEntity: string | null,
  emailFromEntity: string | null,
  nameFromProfile: string
): string | null | undefined => {
  switch (channelId) {
    case ChannelType.D365: {
      let value = `${nameFromEntity}`;
      if (emailFromEntity) {
        value = `${value} <${emailFromEntity}>`;
      }
      return value;
    }
    case ChannelType.Tampuuri:
      return nameFromEntity || 'TAMPUURI_ENTITY';
    case ChannelType.Eezy: {
      let value = `${nameFromEntity}`;
      if (emailFromEntity) {
        value = `${value} <${emailFromEntity}>`;
      }
      return value;
    }
    case ChannelType.SecureEmail:
    case ChannelType.Email:
      return metaData?.from?.[0];
    case ChannelType.Phone:
      return metaData?.phone;
    case ChannelType.Sms:
      return metaData?.sender;
    case ChannelType.Webform:
      return metaData?.email && metaData.email === emailFromEntity
        ? `${nameFromEntity} <${emailFromEntity}>`
        : 'FORM_ENTITY';
    case ChannelType.Chat:
    case ChannelType.Giosg:
      return nameFromEntity || 'CHAT_ENTITY';
    case ChannelType.WhatsApp: {
      let value = `${metaData?.name}`;
      if (metaData?.phone) {
        value = `${value} <${metaData?.phone}>`;
      }
      return value;
    }
    case ChannelType.Internal:
    case ChannelType.Other:
      return nameFromProfile;
    case ChannelType.Instagram: // TODO: need to change integration to have name in metaData
    case ChannelType.Facebook: // TODO: need to change integration to have name in metaData
    default:
      return null;
  }
};

export const getCommentSender = ({
  originalEntityData, // metaData.originalEntity
  ticketEntities,
  customerInfoFields,
  channelData,
  user,
  metaData,
  direction
}: {
  originalEntityData: OriginalEntity | null;
  ticketEntities: Entity[];
  customerInfoFields: Field[];
  channelData: Channel | undefined;
  user: User;
  metaData: CommentMetadata | null;
  direction: TicketDirection | null;
}): string => {
  if (metaData?.bot?.name) {
    // e.g. ViestiTytti
    return metaData.bot.name;
  }

  const nameFromProfile = `${user?.profile?.firstName ?? ''} ${user?.profile?.lastName ?? ''}`.trim();
  if (direction !== 'in') {
    return nameFromProfile;
  }

  if (!channelData?.id) {
    return 'UNKNOWN';
  }

  const senderByChannel = getCommentSenderByChannel(
    channelData?.id,
    metaData,
    getNameFromEntityData(originalEntityData, ticketEntities, customerInfoFields),
    getEmailFromEntityData(originalEntityData, ticketEntities, customerInfoFields),
    nameFromProfile
  );

  return senderByChannel || 'UNKNOWN';
};

/**
 * Takes firstName and lastName from appropriate entity data source according to ticket type fieldSets
 */
export const getNameFromEntityData = (
  originalEntityData: OriginalEntity | null,
  ticketEntities: Entity[],
  customerInfoFields: Field[]
): string | null => {
  const entity = isEmpty(originalEntityData)
    ? ticketEntities[0]?.data
    : // entity data is not nested in case of metaData.originalEntity
      originalEntityData;

  if (!entity) {
    return null;
  }

  return ['name1', 'name2']
    .reduce((nameArray, field) => {
      const fieldName = customerInfoFields.find((entityField) => entityField.displayField === field)?.value;

      return fieldName && entity[fieldName] ? nameArray.concat(entity[fieldName]) : nameArray;
    }, [])
    .join(' ')
    .trim();
};

/**
 * Takes email from appropriate entity data source according to ticket type fieldSets
 */
export const getEmailFromEntityData = (
  originalEntityData: OriginalEntity | null,
  ticketEntities: Entity[],
  customerInfoFields: Field[]
): string | null => {
  const entity = isEmpty(originalEntityData)
    ? ticketEntities[0]?.data
    : // entity data is not nested in case of metaData.originalEntity
      originalEntityData;

  if (!entity) {
    return null;
  }
  const emailFieldName =
    customerInfoFields.find((entityField) => entityField.displayField === 'emailField')?.value ?? 'email';

  return entity[emailFieldName] ?? null;
};

export const getLastComment = (
  comments: Comment[] = [],
  onlyExternal = false,
  direction = Direction.DESC
): Comment | null => {
  const sortedComments = direction === Direction.DESC ? [...comments].reverse() : comments;

  if (onlyExternal) {
    return sortedComments.find((comment) => comment.direction === 'in') || null;
  }

  return sortedComments[0] || null;
};

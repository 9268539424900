import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Form, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';

import type { FormEvent } from 'react';
import type { CheckboxProps } from 'semantic-ui-react';

import AnonymizationApi from 'src/api/AnonymizationApi';
import { useModal } from 'src/Components/Modal/ModalContext';
import { convertPrefixStringToNumber } from 'src/Components/Utilities';

import type { AnonymizationOptions } from 'src/api/AnonymizationApi';
import type { State } from 'src/types/initialState';

const AnonymizeTicket = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const activeTicket = useSelector((state: State) => convertPrefixStringToNumber(state.activeTicketTab!, 'TSK'));
  const [isSubmitting, setSubmitting] = useState(false);

  const [checkboxStates, setCheckboxStates] = useState({
    anonymizeTicketsData: true,
    anonymizeTicketCommentsData: true,
    deleteContentDetails: true,
    anonymizeSurveyAnswers: true,
    detachEntities: true,
    removeUnattachedEntities: true
  } as AnonymizationOptions);

  const handleChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    setCheckboxStates({ ...checkboxStates, [data.name as string]: !!data.checked });

    // Special case: Automatically disable 'removeUnattachedEntities' if 'detachEntitiesFromCases' is unchecked
    if (data.name === 'detachEntities' && !data.checked) {
      setCheckboxStates({ ...checkboxStates, detachEntities: false, removeUnattachedEntities: false });
    }
  };

  const submitAnonymization = async () => {
    setSubmitting(true);

    try {
      await AnonymizationApi.anonymize({
        ticketIds: [activeTicket],
        options: checkboxStates
      });
      closeModal();

      iziToast.success({
        message: t('anonymization.toasts.ticket_success'),
        icon: 'icon check'
      });
    } catch (error) {
      iziToast.error({
        message: t('anonymization.toasts.ticket_fail'),
        timeout: 3000,
        position: 'bottomRight'
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <ModalHeader>{t('anonymization.labels.ticket_anonymization')}</ModalHeader>

      <ModalContent>
        <h3>{t('anonymization.labels.ticket_options')}</h3>
        <Form>
          {Object.entries(checkboxStates).map(([key, value]) => (
            <Form.Field key={key}>
              <Checkbox
                label={t(`anonymization.labels.${key}`)}
                name={key}
                checked={value}
                onChange={handleChange}
                disabled={key === 'removeUnattachedEntities' && !checkboxStates.detachEntities}
              />
            </Form.Field>
          ))}
        </Form>
      </ModalContent>

      <ModalActions>
        <Button content={'Cancel'} labelPosition="right" icon="remove" onClick={() => closeModal()} />

        <Button
          content={t('anonymization.buttons.anonymize')}
          labelPosition="right"
          icon="checkmark"
          positive
          disabled={isSubmitting}
          onClick={submitAnonymization}
        />
      </ModalActions>
    </>
  );
};

export default AnonymizeTicket;

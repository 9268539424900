import React from 'react';
import { useTranslation } from 'react-i18next';

import ChannelType from './ChannelType';
import type { Attachment } from 'src/types/Ticket';

export interface EmailIconMetaData {
  attachments: Attachment[];
  bcc: string[];
  cc: string[];
  from: string[];
  to: string[];
  title: string;
  headers: any;
}

export interface EmailContentProps {
  channel: ChannelType;
  metaData: EmailIconMetaData;
  title: string | null;
}

function Attachments(props: { attachments: Attachment[] }) {
  const { t } = useTranslation();

  if (!Array.isArray(props.attachments) || !props.attachments.length) {
    return <></>;
  }

  return (
    <div>
      <b>{t('COMMMENT_METADATA_ATTACHMENTS')}</b>
      <ol>
        {props.attachments.map((attachment: Attachment, index: number) => {
          return (
            <li key={index}>
              <b>{t('COMMENT_METADATA_TITLE')}:</b>
              <div>{attachment.title}</div>
              <b>{t('FILE_NAME')}:</b>
              <div>{attachment.fileName}</div>
              <b>{t('FILE_TYPE')}:</b>
              <div>{attachment.fileType}</div>
              <b>{t('DESCRIPTION')}:</b>
              <div>{attachment.description}</div>
            </li>
          );
        })}
      </ol>
    </div>
  );
}

function BCC(props: { bcc: string[] }) {
  if (!Array.isArray(props.bcc) || !props.bcc.length) {
    return <></>;
  }

  return (
    <div>
      <b>bcc</b>
      <ol>
        {props.bcc.map((bcc: string, index: number) => {
          return <li key={index}>{bcc}</li>;
        })}
      </ol>
    </div>
  );
}

function CC(props: { cc: string[] }) {
  if (!Array.isArray(props.cc) || !props.cc.length) {
    return <></>;
  }

  return (
    <div>
      <b>cc</b>
      <ol>
        {props.cc.map((bcc: string, index: number) => {
          return <li key={index}>{bcc}</li>;
        })}
      </ol>
    </div>
  );
}

function Body(props: { metaData: { [x: string]: any } }) {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <b>{t('COMMENT_METADATA_SENDER')}</b>
        <div>{props.metaData.from}</div>
      </div>
      <hr />
      <div>
        <b>{t('COMMENT_METADATA_TARGETED_TO')}</b>
        {Array.isArray(props.metaData.to) ? (
          <ol>
            {props.metaData.to.map((to: string, index: number) => {
              return <li key={`email-content-to-${index}`}>{to}</li>;
            })}
          </ol>
        ) : (
          <div>...</div>
        )}
      </div>
    </>
  );
}

function Details(props: { metaData: EmailIconMetaData }) {
  return (
    <>
      <BCC bcc={props.metaData.bcc} />
      <CC cc={props.metaData.cc} />
      <Attachments attachments={props.metaData.attachments} />
    </>
  );
}

export function EmailContent(props: EmailContentProps) {
  const { t } = useTranslation();

  const haveReplyTo = Array.isArray(props.metaData.headers?.['reply-to']);

  return (
    <div style={{ width: '320px' }}>
      <div>
        <b>{t('COMMENT_METADATA_TITLE')}</b>
        <div>{props.title || props.metaData.title}</div>
      </div>
      <Body metaData={props.metaData} />
      {haveReplyTo && (
        <div>
          <b>reply-to</b>
          <ol>
            {props.metaData.headers['reply-to'].value.map((replyTo: any, index: number) => {
              return <li key={index}>{replyTo.address}</li>;
            })}
          </ol>
        </div>
      )}
      <Details metaData={props.metaData} />
    </div>
  );
}

export function SecureEmailContent(props: EmailContentProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <b>{t('COMMMENT_ICON_CONTENT_SECURE_EMAIL_CHANNEL')}</b>
        <hr />
        <b>{t('COMMENT_METADATA_TITLE')}</b>
        <div>{props.title || props.metaData.title}</div>
      </div>
      <Body metaData={props.metaData} />
      <Details metaData={props.metaData} />
    </div>
  );
}

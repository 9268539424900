import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Icon, Message, Table } from 'semantic-ui-react';

import type { ComponentProps, VFC } from 'react';
import type { ConnectedProps } from 'react-redux';

import SalesforceObjectFormField from './SalesforceObjectFormField';
import { getSalesforceObjectFieldsByType } from 'src/Components/Utilities/salesforce';
import { selectActiveTicket } from 'src/selectors/rootStateSelectors';

import type { ISalesforceSettingsData } from 'src/api/EnvSettings';
import type InfoField from 'src/Components/Case/Info/InfoField/InfoField';
import type { State } from 'src/types/initialState';
import type { SalesforceObjectType } from 'src/types/Salesforce';
import type { FieldSet } from 'src/types/TicketType';

type InfoFieldParams = ComponentProps<typeof InfoField>;

interface SalesforceObjectFormProps extends ConnectedProps<typeof connector> {
  fieldSet: FieldSet;
  type: SalesforceObjectType;
  settings: ISalesforceSettingsData;
  values: InfoFieldParams['values'];

  setValue: InfoFieldParams['setValue'];
}

const SalesforceObjectForm: VFC<SalesforceObjectFormProps> = ({
  type,
  values: originalValues,
  fieldSet,
  settings,
  ticket,
  setValue
}) => {
  const { t } = useTranslation();
  const fields =
    useMemo(() => getSalesforceObjectFieldsByType(type, fieldSet, settings), [type, fieldSet, settings]) ?? [];

  const entityId = ticket?.entities[0]?._id;
  const values = useMemo(
    () => ({ ...originalValues, ...settings.fetchPicklistValues }),
    [originalValues, settings.fetchPicklistValues, entityId]
  );

  if (!entityId) {
    return (
      <Message icon negative>
        <Icon name="warning sign" />
        <Message.Content>
          <Message.Header>{t('salesforce_warning.heading')}</Message.Header>
          {t('salesforce_warning.content')}
          <br />
        </Message.Content>
      </Message>
    );
  }

  return (
    <Table>
      <Table.Body>
        {fields.map((field, index) => (
          <SalesforceObjectFormField
            key={`salesforce-tab-field-${index}`}
            field={field}
            fields={fields}
            values={values}
            setValue={setValue}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const connector = connect((state: State) => ({
  ticket: selectActiveTicket(state)
}));

export default connector(SalesforceObjectForm);

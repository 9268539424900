import { t as tr } from 'i18next';
import iziToast from 'izitoast';
import { isEmpty, isEqual } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import sanitizeHTML from 'sanitize-html';
import { Dropdown } from 'semantic-ui-react';

import type { IziToast } from 'izitoast';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { DropdownProps } from 'semantic-ui-react';

import { addTagToContent } from 'src/actions/ticketsActions';
import ResponseTemplatesApi from 'src/api/ResponseTemplatesApi';
import { sortTemplatesNumerical } from 'src/Components/Utilities';
import { tagIdToString } from 'src/types/Tag';
import { fillInTemplateParameters } from 'src/Utilities/params';
import { onResponseTemplatePrompt } from 'src/Utilities/replyContent';
import { newlinesToLineBreaks, removeHTMLTags } from 'src/Utilities/sanitize';

import type { State } from 'src/types/initialState';
import type { CheckForAndAssignParametersOptions, ResponseTemplate, ResponseUpdate } from 'src/types/ResponseTemplate';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';

export interface ReplyTemplateProps {
  userData: PersonalData;
  task: Ticket;
  content: string;
  channel: number;
  ticketType: TicketType;
  entities: Entity[];
  templates: ResponseTemplate[];
  selectedOption: string | undefined;
  toAddresses?: string;
  ccAddresses?: string;
  bccAddresses?: string;
  users?: User[];
  discardHtml?: boolean;
  replyType?: string;

  setContent: (value: ResponseUpdate) => void;
  setSelectedOption: (value: string) => void;
  addTag: (task: string, tagId: string) => void;
  insertAtCursor?: (value: Omit<ResponseUpdate, 'content'>, content: string) => void;
}

export interface ReplyTemplateState {
  loading: boolean;
}

interface DispatchProps {
  addTag: (taskId: string, tagId: string) => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchProps => ({
  addTag: (task, tagId) => {
    dispatch(addTagToContent(task, tagId, false));
  }
});

class ReplyTemplates extends React.Component<ReplyTemplateProps, ReplyTemplateState> {
  constructor(props: ReplyTemplateProps) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    if (this.props.replyType !== 'email' || this.props.content) {
      return;
    }

    const defaultEmailTemplate = this.props.templates.find(
      (template) => template.isDefaultForEmail && template.ticketTypes.includes(this.props.ticketType.id)
    );

    if (defaultEmailTemplate) {
      this.setCurrentTemplate(defaultEmailTemplate._id!);
    }
  }

  componentDidUpdate(prevProps: ReplyTemplateProps) {
    if (this.props.replyType !== 'email' || this.props.content) {
      return;
    }

    if (!isEqual(prevProps.templates, this.props.templates) || prevProps.ticketType.id !== this.props.ticketType.id) {
      const defaultEmailTemplate = this.props.templates.find(
        (template) => template.isDefaultForEmail && template.ticketTypes.includes(this.props.ticketType.id)
      );

      if (defaultEmailTemplate) {
        this.setCurrentTemplate(defaultEmailTemplate._id!);
      }
    }
  }

  private replaceParams = (template: ResponseTemplate): { [x: string]: unknown } | undefined => {
    const { replaceToAddress, replaceCCAddress, replaceBccAddress, changeTitle } = template.params || {};

    return {
      to: replaceToAddress ?? this.props.toAddresses,
      cc: replaceCCAddress ?? this.props.ccAddresses,
      bcc: replaceBccAddress ?? this.props.bccAddresses,
      ...(changeTitle && { subject: changeTitle })
    };
  };

  private checkForAndAssignParameters = (options: CheckForAndAssignParametersOptions): ResponseUpdate => {
    const { templateContent, missingParameters } = fillInTemplateParameters(options);

    if (missingParameters.length > 0) {
      const errorMessages: string[] = [tr('TEMPLATE_PARAMETER_MISSING')];
      const missingParams = missingParameters.map((misParam: string) => `<br/> - ${misParam}`);

      const errorMessage = errorMessages.concat(missingParams).join(' \n');
      iziToast.warning({
        timeout: 0,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        message: errorMessage,
        position: 'center',
        buttons: [
          [
            `<button><b>${tr('GENERAL_CLOSE')}</b></button>`,
            (instance: IziToast, toast: HTMLDivElement) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
            },
            true
          ]
        ]
      });
    }

    const templateParams = this.replaceParams(options.template as ResponseTemplate);

    return {
      content: newlinesToLineBreaks(templateContent),
      ...templateParams
    };
  };

  private addTag = (ticket: Ticket, tagId: string | number): void => {
    const existingTags = this.props.task.tags;
    tagId = tagIdToString(tagId);
    if (existingTags.includes(tagId)) {
      return;
    }

    this.props.addTag(ticket.id, tagId);
  };

  private fireParameterEvents = (template: ResponseTemplate) => {
    const tags = template.params?.addTags;

    if (tags) {
      for (const tag of tags) {
        this.addTag(this.props.task, tag);
      }
    }
  };

  private getUsableTemplates = (templates: ResponseTemplate[]) =>
    templates
      .filter((template: ResponseTemplate) =>
        template.ticketTypes.length === 0 || template.ticketTypes.find((ticketType: number) => ticketType === this.props.ticketType.id)
      )
      .filter((template: ResponseTemplate) =>
        template.channels.find((channel: number) => channel === this.props.channel)
      );

  private onChangeDropdown = async (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    await this.setCurrentTemplate(data.value!.toString());
  };

  private setCurrentTemplate = async (templateId: string, silent = false) => {
    const template = this.props.templates.find((template) => template._id === templateId);

    if (!template) {
      return;
    }
    this.props.setSelectedOption(templateId);

    let typeParams;

    if (this.props.entities && template.type) {
      const entity: Entity = this.props.entities[0];
      this.setState({ loading: true });
      if (isEmpty(this.props.entities)) {
        this.setState({ loading: false });
        if (!silent) {
          iziToast.warning({
            timeout: 0,
            close: false,
            overlay: true,
            id: 'question',
            zindex: 999,
            message: tr('RESPONSE_TEMPLATE_MISSING_ENTITY'),
            position: 'center',
            buttons: [
              [
                `<button><b>${tr('GENERAL_CLOSE')}</b></button>`,
                (instance: IziToast, toast: HTMLDivElement) => {
                  instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
                },
                true
              ]
            ]
          });
        }
      } else {
        const paramsByTemplateType = await ResponseTemplatesApi.getTemplateTypeParams(
          template.type,
          entity._id,
          entity._type,
          this.props.task.id
        );
        this.setState({ loading: false });
        typeParams = paramsByTemplateType.parameters;
      }
    }

    this.fireParameterEvents(template);

    const originalContent = this.props.content;
    const parametrized = this.checkForAndAssignParameters({
      template,
      task: this.props.task,
      userData: this.props.userData,
      entities: this.props.entities,
      handlingAgentString: tr('HANDLING_AGENT'),
      users: this.props.users ?? [],
      typeParams
    });

    if (parametrized === null) {
      return;
    }

    const sanitizedOriginalContent = sanitizeHTML(originalContent, {
      allowedTags: [],
      allowedAttributes: {}
    }).replace(/ /g, '');
    let update: ResponseUpdate = parametrized;

    if (parametrized.content && originalContent && sanitizedOriginalContent.length > 0 && originalContent !== '') {
      const result = await onResponseTemplatePrompt({
        tr,
        originalContent,
        update: parametrized,
        insertAtCursor: typeof this.props.insertAtCursor === 'function'
      });
      const commentContentField = document.getElementsByClassName('fr-element fr-view');
      const targetElement = commentContentField[0] as HTMLElement;
      targetElement?.focus();
      if (result === null) {
        return;
      }
      update = result;
    }

    if (this.props.discardHtml) {
      update.content = removeHTMLTags(update.content);
    }

    if (update.insertAtCursor) {
      const { content, ...value } = update;
      this.props.insertAtCursor?.(value, content);
    } else {
      this.props.setContent({
        ...update,
        contentTemplate: update.content
      });
    }
  };

  render() {
    const usableTemplates = this.getUsableTemplates(this.props.templates);

    return (
      <Dropdown
        fluid
        selection
        search
        loading={this.state.loading}
        selectOnBlur={false}
        selectOnNavigation={false}
        value={this.props.selectedOption || ''}
        placeholder={tr('PLACEHOLDER_SEARCH_CANNED_RESPONSE')}
        noResultsMessage={tr('PLACEHOLDER_SEARCH_NO_RESULTS')}
        onOpen={() => this.props.setSelectedOption('')}
        onChange={this.onChangeDropdown}
        options={sortTemplatesNumerical(usableTemplates).map((template) => ({
          key: template._id,
          text: template.name,
          value: template._id
        }))}
      />
    );
  }
}

export default connect(null, mapDispatchToProps)(ReplyTemplates);
